<style>
    .bg-mgi {
        background-image: url('../assets/bg/dark-bg-wide.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .audio-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .captions {
        margin-top: 20px;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        border-radius: 5px;
        width: 90%;
        text-align: center;
    }

    .legendas {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        text-align: center;
    }

    #audio-astrology .audio-astrology-container {
        height: 100%;
        width: calc(100% - 70px);
    }


    #audio-astrology .vjs-has-started .vjs-control-bar {
        display: none;
    }

    #audio-astrology .vjs-text-track-cue {
        height: 100% !important;
        top: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #audio-astrology .vjs-text-track-cue>div {
        font-family: 'Poppins' !important;
        font-size: 1.5rem;
        text-align: center;
        font-weight: 500;
        background: initial !important;
    }

    #audio-astrology .buttons-audio-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        width: 69px;
        height: fit-content;
        padding: 25px 20px;
        border-left: 4px solid #ffffff;
        position: absolute;
        right: 0;
        top: calc(50% - 55.5px);
    }

    #audio-astrology .button-video .fas {
        display: none;
        color: #fff;
    }

    #audio-astrology .start-pause-audio .fas {
        font-size: 1.375rem;
    }

    #audio-astrology .volume-video .fas {
        font-size: 1.5rem;
    }

    #audio-astrology .start-pause-audio.active .fa-pause,
    #audio-astrology .start-pause-audio.false .fa-play {
        display: block;
    }

    #audio-astrology .volume-video.active .fa-volume-up,
    #audio-astrology .volume-video.false .fa-volume-off {
        display: block;
    }

    #audio-astrology .vjs-text-track-display {
        bottom: initial !important;
        position: static;
        height: 100%;
    }

    #audio-astrology .vjs-text-track-display>div {
        position: static !important;
        height: 100%;
        margin: 0 !important;
    }
</style>

<template lang="br">
    <div id="page-wrapper" class="page-wrapper">
        <div class="background-image-sign">
            <img v-if="backgroundVSL" src="../assets/imgs/astrology/dark-bg-wide.jpg" alt="">
        </div>
        <div class="container">
            <div class="ga4983248 container">
                <section class="eh90427" v-if="section1">
                    <div class="hf-4920">
                        <img src="../assets/logo.png" style="max-width: 400px; margin-bottom: 20px">
                        <p v-if="currentStep === 1" class="section-p" style="text-align: center; font-family: 'Sora', sans-serif; font-size: 22px; line-height: 1.3em; color: #060606">
                            Sua leitura gratuita da Lua explora os segredos profundos para uma vida no <span
                                style="text-transform: normal; color: #fff; font-family: 'Sora', sans-serif" class="gradient-text">Amor, Saúde,
                                Felicidade e Finanças!</span>
                        </p>
                    </div>
                    <div class="form-modal">
                        <div class="form-container">
                            <div class="ms1 form-content" v-if="currentStep === 1">
                                <br />
                                <h3
                                    style="font-family: Sora, sans-serif; font-size: 26px; font-weight: 500; color: #060606; border-radius: 4px; padding: 4px; margin: 0px">
                                    PASSO 1: Toque no SEU SIGNO abaixo</h3>
                                <br />
                                <div class="sbc1945">
                                    <button class="bs5829" @click="handleButtonSignClick('Áries', $event)"
                                        id="click-signo"><img src="../assets/imgs/astrology/i-aries-desk.png" alt="">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            ÁRIES<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">21/03
                                                - 19/04</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Touro', $event)"
                                        id="click-signo"><img src="../assets/imgs/astrology/i-touro-desk.png" alt="">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            TOURO<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">20/04
                                                - 20/05</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Gêmeos', $event)"
                                        id="click-signo"><img src="../assets/imgs/astrology/i-gemeos-desk.png" alt="">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            GÊMEOS<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">21/05
                                                - 21/06</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Câncer', $event)"
                                        id="click-signo"><img src="../assets/imgs/astrology/i-cancer-desk.png" alt="">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            CÂNCER<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">22/06
                                                - 22/07</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Leão', $event)"
                                        id="click-signo"><img src="../assets/imgs/astrology/i-leao-desk.png" alt="">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            LEÃO<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">23/07
                                                - 22/08</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Virgem', $event)"
                                        id="click-signo"><img src="../assets/imgs/astrology/i-virgem-desk.png" alt="">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            VIRGEM<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">23/08
                                                - 22/09</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Libra', $event)"
                                        id="click-signo"><img src="../assets/imgs/astrology/i-libra-desk.png" alt="">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            LIBRA<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">23/09
                                                - 22/10</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Escorpião', $event)"
                                        id="click-signo"><img src="../assets/imgs/astrology/i-escorpiao-desk.png"
                                            alt="">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            ESCORPIÃO<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">23/10
                                                - 21/11</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Sagitário', $event)"
                                        id="click-signo"><img src="../assets/imgs/astrology/i-sagitario-desk.png"
                                            alt="">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            SAGITÁRIO<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">22/11
                                                - 21/12</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Capricórnio', $event)"
                                        id="click-signo"><img src="../assets/imgs/astrology/i-capricornio-desk.png"
                                            alt="">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            CAPRICÓRNIO<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">22/12
                                                - 19/01</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Aquário', $event)"
                                        id="click-signo"><img src="../assets/imgs/astrology/i-aquario-desk.png" alt="">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            AQUÁRIO<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">20/01
                                                - 18/02</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Peixes', $event)"
                                        id="click-signo"><img src="../assets/imgs/astrology/i-peixes-desk.png" alt="">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            PEIXES<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">19/02
                                                - 20/03</span></p>
                                    </button>
                                </div>


                            </div>
                            <div class="form-content" v-if="currentStep === 2">
                                <h3>Qual seu Dia de Nascimento?</h3>
                                <div class="dsc698">
                                    <div class="id2938 dc48194">
                                        <div class="header31283">
                                            <h4 style="margin: 0px;">{{startDateField}}</h4>
                                        </div>
                                        <div class="dt948389">
                                            <ul class="ls318329">
                                                <li v-for="day in varListInitialDays" :data-value="day"
                                                    @click="handleDayClick(day, 'start', startDateField)">
                                                    {{ day }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="id784738 dc48194">
                                        <div class="header31283">
                                            <h4 style="margin: 0px;">{{endDateField}}</h4>
                                        </div>
                                        <div class="dt948389">
                                            <ul class="ls318329">
                                                <li v-for="day in varListEndDays" :data-value="day"
                                                    @click="handleDayClick(day, 'end', endDateField)">
                                                    {{ day }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <button class=" vl43829" @click="handleBackBtnMoonSign()"
                                    style="display:block;text-align:center; margin-top: 30px;"><img
                                        src="../assets/imgs/white-back.png" style="height: 50px;"></button>
                            </div>
                            <div class="form-content" v-if="currentStep === 3">
                                <h3>Em qual Década você nasceu?</h3>
                                
                                <div class="dc48438">
                                    <ul class="ls318329">
                                        <li v-for="decade in decades" :key="decade" :data-value="decade"
                                            @click="handleDecadeClick(decade)"
                                            :class="{'btn-item': true, 'decade-option': true, 'active': decade === decadeValue}">
                                            {{ decade }}
                                        </li>
                                    </ul>
                                </div>
                                <button class=" vl43829" @click="handleBackBtnMoonSign()"
                                    style="display:block;text-align:center; margin-top: 30px;"><img
                                        src="../assets/imgs/white-back.png" style="height: 50px;"></button>
                            </div>
                            <div class="form-content" v-if="currentStep === 4">
                                <h3>Em que Ano você nasceu?</span></h3>
                                <div class="yc238192448329">
                                    <ul class="ls318329">
                                        <li v-for="year in years" :key="year" :data-value="year"
                                            @click="handleYearClick(year)"
                                            :class="{'o132921': true, 'active': year === yearValue, 'btn-item': true}">
                                            {{ year }}
                                        </li>
                                    </ul>
                                </div>
                                <button class=" vl43829" @click="handleBackBtnMoonSign()"
                                    style="display:block;text-align:center; margin-top: 30px;"><img
                                        src="../assets/imgs/white-back.png" style="height: 50px;"></button>
                            </div>
                            <div class="stf3132189319 form-content" v-if="currentStep === 5">
                                <div class="hqt13812938198 ">
                                    <h3>Você lembra a Hora em que nasceu?</span></h3>

                                    <div class="btc38912312989">
                                        <button class="b2das289914" style="background: #19a10b !important"
                                            @click="stepFiveQuestion('Sim')">Sim</button>
                                        <button class="b2das289914" style="background: #e32121 !important"
                                            @click="stepFiveQuestion('Não')">Não</button>
                                    </div>
                                    <button class=" vl43829" @click="handleBackBtnMoonSign()"
                                        style="display:block;text-align:center; margin-top: 30px;"><img
                                            src="../assets/imgs/white-back.png" style="height: 50px;"></button>
                                </div>
                                <div class="hbc12391949 hide">
                                    <h3>Preencha a hora do seu nascimento</h3>
                                    <div class="input-control hf143154331">
                                        <div class="mhc12310">
                                            <div class="select-modal mwc13213100700" style="display: flex;flex-direction: row;column-gap: 20px;">
                                              <select v-model="selectedHour" class="form-control">
                                                <option value="" disabled selected>Horas</option>
                                                <option v-for="hour in hourOptions" :value="hour">{{ hour }}</option>
                                              </select>
                                              <select v-model="selectedMinute" class="form-control">
                                                <option value="" disabled selected>Minutos</option>
                                                <option v-for="minute in minuteOptions" :value="minute">{{ minute }}</option>
                                              </select>
                                            </div>
                                            <button class="btn-hours-confirm" @click="confirmHours">Confirmar</button>
                                        </div>
                                    </div>
                                    <button class="2312312314ddd-42-vl43829 vl43829" @click="backPreencherHoras()"
                                        style="display:block;text-align:center; bottom: -130px !important;"><img
                                            src="../assets/imgs/white-back.png" style="height: 50px;"></button>
                                </div>
                            </div>
                            <div class="sl31239199998773343" v-if="currentStep === 5.5">
                                <h3 style="color: #060606">Personalizando a sua leitura...</h3>
                                <div class="progress-bar">
                                    <div class="progress-fill"></div>
                                </div>
                            </div>
                            <div class="sx13320008666 form-content" v-if="currentStep === 6">
                                <div class="cq194839829328" v-if="!countrySelectorIsTrue">
                                  <h3>Você nasceu no Brasil?</h3>
                                  <div class="bmc123800488 btc38912312989">
                                    <button class="b2das289914" @click="clickCountryButtonTrue" style="background: #19a10b !important">Sim</button>
                                    <button class="b2das289914" @click="clickCountryButtonFalse" style="background: #e32121 !important">Não</button>
                                  </div>
                                  <button class=" vl43829" @click="handleBackBtnMoonSign()" style="display:block;text-align:center; margin-top: 30px;">
                                    <img src="../assets/imgs/white-back.png" style="height: 50px;" />
                                  </button>
                                </div>
                                <div class="clc1328919388" v-if="countrySelectorIsTrue">
                                  <h3>Em qual país você nasceu?</h3>
                                  <div class="cm4999d338388 mwc13213100700">
                                    <div class="mwh31902190 cm4999d338388-head">
                                      <input type="text" id="c14134ff423432" placeholder="Qual o seu país" disabled class="c14134ff423432" v-model="countryValue">
                                      <div class="mi139102000">
                                        <i class="fas fa-chevron-down"></i>
                                      </div>
                                    </div>
                                    <ul class="cl41031000438830000 mwlk123910020000354699" style="display: block !important" v-show="isCountryModalActive">
                                      <li v-for="country in countries" :key="country.value" :data-value="country.value" @click="selectCountry(country)">
                                        <img :src="country.flag" alt="">
                                        <span>{{ country.name }}</span>
                                      </li>
                                    </ul>
                                  </div>
                                  <button class=" vl43829" @click="backBornGringo()" style="display:block;text-align:center; margin-top: 30px;">
                                    <img src="../assets/imgs/white-back.png" style="height: 50px;" />
                                  </button>
                                </div>
                            </div>
                            <div class="s7s1238s7sd30007600000 form-content" v-if="currentStep === 7">
                                <div class="input-control ffn312312900nbn432420000">
                                    <h3>Qual é o seu Primeiro Nome?</span></h3>
                                    <div class="">
                                        <input type="text" id="f131238918284" value="" v-model="firstName" @change="handleFieldFirstName" class="field-input" placeholder="Digite seu nome" autocomplete="given-name">
                                        <div class="error-field" v-html="errorFieldMessage"></div>
                                    </div>
                                </div>
                                <div class="form-button">
                                    <button class="button-form" @click="handleClickMoonSign()" style="background: #19a10b !important">Clique
                                        Aqui Para Continuar!</button>
                                </div><br /><br />
                                <button class="vl43829" style="display:block;text-align:center; margin-top: 30px;" @click="handleBackBtnMoonSign()"><img
                                    src="../assets/imgs/white-back.png" style="height: 50px;"></button>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section-moon-phases eh90427" v-if="parte2">
                    <div class="form-modal">
                        <div class="form-container">
                            <div class="form-content" v-if="parteDoisSexo">
                                <h3 style="font-family: 'Sora', serif; margin-top: 40px; color: #fff">Qual seu sexo?</h3>
                                <div class="geme1293129031200049303000000">
                                    <div class="cgm123928319000032000">
                                        <button class="bgnnj443000003455430000"
                                            @click="clickButtonGender('masculino')">
                                            <img style="width: 100px; height: auto"
                                                src="../assets/imgs/astrology/masculine.png" alt="">
                                            <div class="g43183198c00001230900000">
                                                <input type="radio" name="gender" id="masculine" value="masculino"
                                                    v-model="genderValue">
                                                <label for="masculine"
                                                    style="font-family: 'Sora', serif;">Masculino</label>
                                            </div>
                                        </button>
                                        <button class="bgnnj443000003455430000"
                                            @click="clickButtonGender('feminino')">
                                            <img style="width: 100px; height: auto"
                                                src="../assets/imgs/astrology/female.png" alt="">
                                            <div class="g43183198c00001230900000">
                                                <input type="radio" name="gender" id="female" value="feminino"
                                                    v-model="genderValue">
                                                <label for="female"
                                                    style="font-family: 'Sora', serif;">Feminino</label>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="mst212319020001230000 form-content" v-if="parteDoisEstadoCivil">
                                <div class="mwc13213100700">
                                    <h3 style="margin-top: 40px; color: #fff">Escolha abaixo o seu <span
                                            style="text-transform: normal;font-weight: bold;">Estado Civil:</span></h3>
                                    <div class="mwh31902190">
                                        <!-- Input select com v-model -->
                                        <select v-model="selectedStatus" class="form-control">
                                            <option value="" disabled>Selecione seu estado civil</option>
                                            <option v-for="status in statuses" :key="status" :value="status">
                                                {{ status }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-button">
                                    <button class="button-form" style="background: #19a10b !important" @click="goToParte2()">Clique para
                                        continuar</button>
                                </div>
                                <button class="vl43829"><img src="../assets/imgs/white-back.png" style="height: 50px;" @click="backEstadoCivil"></button>
                            </div>

                        </div>
                    </div>
                    <!--   <div class="audio-modal" id="audio-astrology">
                        <div class="audio-astrology-container">
                            <audio id="my-player" class="video-js">
                                <source id="my-spanish-audio-track" src="" type="audio/ogg" />
                                <track kind="captions" src="" srclang="br" label="Português" default />
                            </audio>
                        </div>
                        <div class="buttons-audio-container">
                            <button class="button-video start-pause-audio false">
                                <i class="fas fa-pause"></i>
                                <i class="fas fa-play"></i>
                            </button>
                            <button class="button-video volume-video active">
                                <i class="fas fa-volume-up"></i>
                                <i class="fas fa-volume-off"></i>
                            </button>
                        </div>
                    </div> -->
                </section>
                <section class="sm1231231100002300000 eh90427" v-if="parte3">
                    <div class="hf-4920">
                      <p class="section-p" style="font-family: 'Sora', serif; margin-top: 40px">
                        Digite o seu <b>e-mail</b> para receber o restante da sua <b>leitura personalizada...</b>
                      </p>
                    </div>
                    <div class="rm00301293012000032900 form-content" style="min-height: 145px;">
                      <h3 style="font-family: 'Sora', serif; color: white">Qual é o seu Email? </h3>
                      <div class="input-control fc1391820000123000">
                        <input 
                          type="email" 
                          name="email-field" 
                          id="email-field" 
                          v-model="emailValue" 
                          class="field-input" 
                          placeholder="Digite seu Email" 
                          >
                        <div class="error-field" v-if="emailErrorMessage">{{ emailErrorMessage }}</div>
                      </div>
                    </div>
                    <div class="form-button" style="margin-bottom: 30px;">
                      <button 
                        class="button-form" 
                        style="font-family: 'Sora', serif; background: #19a10b !important" 
                        @click="validateEmail">
                        Clique para continuar
                      </button>
                    </div>
                  </section>
                <section class="ps03120003100000" v-if="parte4">
                    <div class="pm132i1092321200">
                        <div class="mc193091200003912000">
                            <div class="">
                                <div class="mp3130120000">
                                    <h3 style="font-family: 'Sora', serif; color: white">Você quer aprofundar na sua Leitura?
                                    </h3>
                                </div>
                                <div class="mb312319909321009990">
                                    <button class="" @click="handleModalPitch"
                                        style="font-family: 'Sora', serif; background: #19a10b !important">Sim, Eu
                                        Quero</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="cc13912839119900000" v-if="cta">
                    <div class="c13291023000391200m">
                        <header class="c3120j019h01390120000" style="color: white">
                            Clique no botão abaixo para garantir o seu Mapa Astrológico - <span>OFERTA RELÂMPAGO!<br>De
                                <s>R$ 497</s> Por Apenas 12x R$19,78</span>
                        </header>

                        <div class="c3921839012ct103210000">
                            <div class="ctp13039120310000">
                                <div class="c930213901i3912090100">
                                    <a class="cta-img" href="https://pay.kiwify.com.br/Q1SGP6T"><img
                                            src="../assets/imgs/astrology/mapa-p4-cta-por197.png" alt=""
                                            srcset="" /></a>
                                </div>

                                <div class="a312312312930120x0x000t">
                                    <div class="c13910b010213000">
                                        <h3 style="color:red;">OFERTA RELÂMPAGO!<br>De <s>R$ 497</s><br>Por Apenas 12x
                                            R$19,78</h3>

                                        <a class="b0123912090yt1039120310" href="https://pay.kiwify.com.br/Q1SGP6T">COMPRAR AGORA
                                            (enquanto é tempo!)</a>
                                    </div>

                                    <div class="cq39123819000s000391200ss">
                                        <img src="../assets/imgs/cadeado.png" alt="" />
                                        <p>Compra 100% segura</p>
                                    </div>

                                    <div class="c903120000o0319302100000">
                                        <img src="../assets/imgs/formas-de-pagamento.png" alt="" width="100%" />
                                    </div>

                                    <div class="c013120000pt03000000">
                                        <h3>Mais de meio milhão de pessoas encomendaram o seu Mapa Astrológico!</h3>
                                    </div>

                                    <div class="c193021900003912000">
                                        <img src="../assets/imgs/depoimento.png" alt="" />
                                        <p>"Quando eu terminei a leitura do meu Mapa Astrológico Completo, senti que
                                            tinha todas as explicações do que já aconteceu comigo e estou muito mais
                                            segura porque agora sei os melhores caminhos para o meu futuro!"</p>
                                    </div>

                                </div>
                            </div>
                            <div class="c1093221030000310000wa0000">
                                <img src="../assets/imgs/garantia.png" alt="">
                                <h3>Faça sua compra agora e você receberá seu Mapa Astrológico Completo. Sabemos que
                                    você obterá um enorme valor com esta análise detalhada e personalizada, mas, se
                                    dentro do prazo de 7 dias você decidir que não está 100% satisfeito com seu Mapa
                                    Astrológico Completo, envie-nos um e-mail para contato@mahilaluz.com solicitando o
                                    seu reembolso.</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="animation-section hide">
                    <div class="animation-container">
                        <div class="play-animation modal-warning">
                            <div class="content-warning">
                                <h3><br><span style="color: #ffdb60"></span>, sua leitura vai sair do ar em breve.</h3>
                                <img src="https://video.astranumerica.com.br/assets/imgs/stop.png?token=c1xInIi5_xcmFEuooSjaOKx7NvB0-VarLC7iBEC-ZJk&expires=1719662181"
                                    alt="" />
                                <p>Essa é a sua última chance de assistir até o final. <br>
                                    Clique no botão abaixo…</p>
                            </div>
                            <button class="btn-play" style="background: #19a10b !important" @click="continuarVideo(textoWarning)">{{textoWarning}}</button>
                        </div>
                        <div class="modal-animation">
                            <div class="modal-audio-container" style="color: white"></div>
                        </div>
                    </div>
                </section>
                <div class="audio-modal" id="audio-astrology" v-show="showPlayer">
                    <div class="audio-astrology-container">
                        <audio id="my-player" class="video-js" crossorigin=anonymous x-webkit-airplay="deny">
                            <source id="my-spanish-audio-track" src="" type="audio/ogg" crossorigin=anonymous />
                            <track kind="captions" src="#" srclang="br" label="Português" default crossorigin=anonymous />
                        </audio>
                    </div>
                    <div class="buttons-audio-container">
                        <button class="button-video start-pause-audio false" @click="pauseAudio">
                            <i class="fas fa-pause" v-if="pauseIcon == false"></i>
                            <i class="fas fa-play" v-if="pauseIcon == true"></i>
                        </button>
                        <button style="display: none;" class="button-video volume-video active">
                            <i class="fas fa-volume-up"></i>
                            <i class="fas fa-volume-off"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="loading-container" v-if="loadingBlock">
            <h3 style="color: white">Carregando a sua leitura de lua....</h3>
            <div class="progress-bar">
                <div class="progress-fill"></div>
            </div>
        </div>
        <!--<audio loop="true" class="audio-loop">
            <source src="../assets/audio/fundo-loop-novo.mp3" />
        </audio>-->
    </div>
</template>

<script src="../js/video.min.js"></script>
<script src="../js/astrology/moonCalc.js?y=827"></script>
<script src="../js/global.js"></script>

<script>
    import gsap from "gsap";
    import NoSleep from 'nosleep.js';
    import {
        CSSPlugin
    } from "gsap/CSSPlugin";

    import axios from 'axios';

    export default {
        name: 'TesteVSLNova',
        data() {
            return {
                selectedHour: "",
                selectedMinute: "",
                hourOptions: [],
                minuteOptions: [],
                currentStep: 1,
                noSleep: new NoSleep(),
                noSleepEnabled: false,
                elements: {},
                signValue: null,
                moonSign: null,
                moonPhases: null,
                moonPhasesPath: null,
                moonSignStep: 1,
                MoonPhasesStep: 1,
                monthValue: null,
                monthNameValue: null,
                dayValue: null,
                decadeValue: null,
                yearValue: null,
                dateValue: null,
                ageUserValue: null,
                hoursFieldIsTrue: false,
                hoursBirthdate: null,
                hoursValue: undefined,
                minutesValue: undefined,
                countryValue: null,
                firstName: '',
                errorFieldMessage: '',
                firstNameValue: undefined,
                countrySelectorIsTrue: false,
                isCountryModalActive: false,
                countries: [
                    { name: "Argentina", value: "Argentina", flag: "../imgs/astrology/flags/ar.svg" },
                    { name: "Canadá", value: "Canadá", flag: "../imgs/astrology/flags/ca.svg" },
                    { name: "Chile", value: "Chile", flag: "../imgs/astrology/flags/cl.svg" },
                    { name: "Colômbia", value: "Colômbia", flag: "../imgs/astrology/flags/co.svg" },
                    { name: "Equador", value: "Equador", flag: "../imgs/astrology/flags/ec.svg" },
                    { name: "Espanha", value: "Espanha", flag: "../imgs/astrology/flags/es.svg" },
                    { name: "Japão", value: "Japão", flag: "../imgs/astrology/flags/jp.svg" },
                    { name: "Paraguay", value: "Paraguay", flag: "../imgs/astrology/flags/pr.svg" },
                    { name: "Portugal", value: "Portugal", flag: "../imgs/astrology/flags/pt.svg" },
                    { name: "EUA", value: "EUA", flag: "../imgs/astrology/flags/us.svg" },
                    { name: "Uruguay", value: "Uruguay", flag: "../imgs/astrology/flags/uy.svg" },
                    { name: "Outro", value: "Outro", flag: "" }
                ],
                firstNameValue: null,
                genderValue: null,
                civilStatusValue: null,
                emailValue: null,
                emailErrorMessage: null,
                lastDecade: null,
                srcAudio: null,
                formsSectionsArray: [],
                pathCard: "assets/imgs/card.png",
                activeSign: null,
                startDateField: null,
                endDateField: null,
                varListInitialDays: [],
                varListEndDays: [],
                decades: [],
                decadeValue: null,
                lastDecade: null,
                years: [],
                yearValue: null,
                moonDataJS: null,
                playerVideo: null,
                modelVH: null,
                modelVW: null,
                animation: null,
                formsSections: null,
                animationTimeLine: null,
                animation: null,
                _targets: [],
                genderInputMan: null,
                genderInputWoman: null,
                genderValue: null,
                modalActive: false,
                selectedStatus: '',
                statuses: ["Casado(a)", "Namorando", "Noivo(a)", "Solteiro(a)", "Separado(a)", "Viúvo(a)"],
                moonSignCerto: null,
                pauseIcon: false,
                textoWarning: 'COMEÇAR',
                backgroundVSL: false,
                h2: true,
                parte2: false,
                parteDoisSexo: true,
                parteDoisEstadoCivil: false,
                parte3: false,
                parte4: false,
                cta: false,
                showPlayer: false,
                loadingBlock: false,
                section1: true
            };
        },
        mounted() {
            gsap.registerPlugin(CSSPlugin);
            gsap.ticker.lagSmoothing(0);
            this.generateHourOptions();
            this.generateMinuteOptions();
            this.elements = {
                sectionMoonPhases: document.querySelector(".section-moon-phases"),
                sectionMoonReading: document.querySelector(".sm1231231100002300000"),
                sectionPitch: document.querySelector(".ps03120003100000"),
                sectionCta: document.querySelector(".cc13912839119900000"),
                sectionAnimation: document.querySelector(".animation-section"),
                audioBar: document.querySelector("#audio-astrology"),
                audioElement: document.querySelector("#audio-astrology #my-player"),
                audioSection: document.querySelector("#audio-astrology .start-pause-audio"),
                buttonPlayAudio: document.querySelector("#audio-astrology .start-pause-audio"),
                buttonVolumeAudio: document.querySelector("#audio-astrology .volume-video"),
                modalElement: document.querySelector(".modal-audio-container"),
                audioLoop: document.querySelector(".audio-loop")
            };

            this.formsSectionsArray = [{
                    name: "[form2]",
                    section: this.elements.sectionMoonPhases
                },
                {
                    name: "[form3]",
                    section: this.elements.sectionMoonReading
                },
                {
                    name: "[form4]",
                    section: this.elements.sectionPitch
                },
                {
                    name: "[cta]",
                    section: this.elements.sectionCta
                }
            ];

            this.noSleep.enable(); // Ativa o NoSleep
            this.noSleepEnabled = true;

        },
        methods: {
            handleBackBtnMoonSign() {
                if (this.currentStep > 1) {
                    this.currentStep--;
                }
            },
            generateHourOptions() {
                let hours = [];
                for (let hour = 0; hour < 24; hour++) {
                    const formattedHour = hour < 10 ? `0${hour}` : hour;
                    hours.push(formattedHour);
                }
                this.hourOptions = hours;
            },
            generateMinuteOptions() {
                let minutes = [];
                for (let minute = 0; minute < 60; minute++) {
                    const formattedMinute = minute < 10 ? `0${minute}` : minute;
                    minutes.push(formattedMinute);
                }
                this.minuteOptions = minutes;
            },
            handleClickMoonSign() {
                switch (this.currentStep) {
                    case 1:
                        this.handlestepOneMoonSign();
                        break;
                    case 2:
                        this.handlestepTwoMoonSign();
                        break;
                    case 3:
                        this.handlestepThirdMoonSign();
                        break;
                    case 4:
                        this.handleStepFourthMoonSign();
                        break;
                    case 5:
                        this.handleStepFiveMoonSign();
                        break;
                    case 6:
                        this.handleStepSixMoonSign();
                        break;
                    default:
                        this.handleStepSeventhMoonSign();
                        break
                }
            },
            handlestepOneMoonSign() {
                if (!this.signValue) {
                    alert("Por favor selecione seu signo!");
                    return false
                }
                this.currentStep = 2;
                this.generatorDates();
            },
            handlestepTwoMoonSign() {
                if (!this.dayValue) {
                    alert("Por favor selecione sua data de nascimento!");
                    return false
                }
                this.currentStep = 3;
                this.generatorDecade()
            },
            handlestepThirdMoonSign() {
                if (!this.decadeValue) {
                    alert("Por favor selecione sua década de nascimento!");
                    return false
                }
                this.currentStep = 4;
                this.generatorYears()
            },
            handleStepFourthMoonSign() {
                if (!this.yearValue) {
                    alert("Por favor selecione seu ano de nascimento!");
                    return false
                }
                this.currentStep = 5;
            },
            handleStepFiveMoonSign() {
                if (this.hoursFieldIsTrue && !this.hoursBirthdate) {
                    alert("Por favor selecione seu horário de nascimento!");
                    return
                }
                this.currentStep = 5.5;
                setTimeout(() => {
                    this.currentStep = 6;
                }, 2000);
            },
            handleStepSixMoonSign() {
                if (this.countrySelectorIsTrue && !this.countryValue) {
                    alert("Por favor selecione seu país de nascimento!");
                    return
                }
                this.currentStep = 7;
                this.handleFieldFirstName();
            },
            handleStepSeventhMoonSign() {
                if (!this.firstNameValue) {
                    alert("Campo do primeiro nome inválido!");
                    return;
                }
                const alertModal = document.querySelector(".modal-warning .content-warning h3 span");
                alertModal.innerHTML = this.firstNameValue;
                this.section1 = false;
                this.FormMoonSignIsCompleted();
            },
            async FormMoonSignIsCompleted() {
                this.backgroundVSL = true;
                this.loadingBlock = true;
                const monthIncrement = Number(this.monthValue) + 1;
                const monthFormatted = monthIncrement < 10 ? ("0" + monthIncrement) : String(monthIncrement);
                const dayFormatted = this.dayValue < 10 ? ("0" + this.dayValue) : String(this.dayValue);
                const formattedDate = (monthFormatted + "-" + this.dayValue + "-" + this.yearValue);
                this.dateValue = (this.dayValue + "/" + monthFormatted + "/" + this.yearValue);
                var birthDateString = this.yearValue + "-" + monthFormatted + "-" + dayFormatted + "T00:00";
                const birthDate = new Date(birthDateString);
                let moonData = this.datasForDay(birthDate);
                this.moonSign = this.getMoonSignTranslate(moonData.constellation);

                const audioPath = "../audio/astrology/p1/p1-" + this.moonSign.toLowerCase() + ".mp3";
                const jsonPath = "../animations/astrology/p1/p1-" + this.moonSign.toLowerCase() + ".json";
                const videoPath = "../legends/astrology/p1/p1-" + this.moonSign.toLowerCase() + ".vtt";

                const moonDataJson = await this.getJsonData(jsonPath);
                this.moonPhases = this.getMoonPhasesFormatted(moonData.phase);


                setTimeout(() => {
                    this.showPlayer = true;
                    this.elements.sectionAnimation.classList.remove("hide");
                    this.elements.audioSection.classList.remove("hide");
                    this.elements.audioBar.classList.remove("hide");
                    this.loadingBlock = false;
                    this.elements.sectionAnimation.classList.remove("hide");

                    if (moonDataJson) {

                        this.generatorAnimation(moonDataJson); // Gera a animação com os dados obtidos
                    }
                }, 2000);

                //this.moonPhasesGender();
                this.handleVideo(audioPath, videoPath, this.elements.audioElement);
                this.handleStartVideo(true);
                this.ageUserValue = this.getAgeUser();
                this.handleFormsSections(this.formsSectionsArray);
            },
            handleFieldFirstName() {
                const value = this.firstName;
                const numberPattern = /[0-9]/;
                const multipleNamesPattern = /[a-z]+[ ]+[a-z]/g;

                if (numberPattern.test(value)) {
                    this.errorFieldMessage = "*Apenas letras são permitidas!";
                    this.firstNameValue = undefined;
                    return;
                }
                if (multipleNamesPattern.test(value)) {
                    this.errorFieldMessage = "*Apenas o primeiro nome!";
                    this.firstNameValue = undefined;
                    return;
                }
                this.errorFieldMessage = "";
                const valueFormated = value.replace(/ /g, "");
                this.firstNameValue = valueFormated;
            },
            handleEmailEmailValidations(field) {
                let emailRegex = /^([A-Za-z0-9._-])+@([A-Za-z0-9_-])+\.([A-Za-z]{2,4})$/;
                const validationEmail = document.querySelector(".validation-email");
                if (!emailRegex.test(field.value)) {
                    validationEmail.classList.remove("hide");
                    this.emailValue = undefined
                } else {
                    validationEmail.classList.add("hide");
                    this.emailValue = field.value
                }
            },
            handleClickMoonPhases() {
                if (!this.moonPhasesPath) {
                    alert("O campo da fase lunar está vazio!");
                    return
                }
                const stepFiveMoonReading = document.querySelector(".sing-moon-reading-step-fifth");
                const stepFiveMoonPhases = document.querySelector(".sing-moon-reading-step-six");
                stepFiveMoonReading.classList.add("hide");
                stepFiveMoonPhases.classList.remove("hide");
                this.MoonPhasesStep = 6
            },
            async handleCurrentData(month, day, year) {
                return new Promise((resolve, reject) => {
                    var currentTime = new Date();
                    if (!this.hoursValue) this.hoursValue = currentTime.getHours();
                    if (!this.minutesValue) this.minutesValue = currentTime.getMinutes();
                    var d = new Date(year + "-" + month + "-" + day + " " + this.hoursValue + ":" + this
                        .minutesValue);
                    resolve({
                        moonPhase: this.getMoonPhase(d)
                    })
                    reject({

                    })
                })
            },
            handleMoonPhasesPath(currentMoonPhase) {
                return this.getMoonPhasesFormatted(currentMoonPhase)
            },
            continuarVideo(texto){
                if(texto == 'COMEÇAR'){
                    this.textoWarning = 'CONTINUAR';
                }

                this.pauseIcon = false;

            },
            pauseAudio() {
                if(this.pauseIcon == true){
                    this.pauseIcon = false;
                }else{
                    this.pauseIcon = true;
                }

                const modalWarning = document.querySelector('.modal-warning');

                modalWarning.classList.remove('hide');

                this.handlePlayVideo();
            },
            initGSAPAnimation(path, data) {
                gsap.timeline({
                    defaults: {
                        duration: 1
                    },
                    onStart: () => {
                        this.loadingBlock = false;
                    }
                }).to("#path", {
                    duration: 1,
                    attr: {
                        d: path
                    }
                }).fromTo(data, {
                    opacity: 0,
                    y: 100
                }, {
                    opacity: 1,
                    y: 0,
                    stagger: 0.1
                })
            },
            getJsonData(path) {
                const cleanedPath = path.replace(/\/+$/, "");
                return fetch(cleanedPath)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    return data;
                })
                .catch((error) => {
                    console.error("Failed to fetch data:", error);
                    return [];
                });
            },
            getMoonSignTranslate(sign) {
                const moonSignDictionary = {
                    Aries: "aries",
                    Taurus: "touro",
                    Gemini: "gemeos",
                    Cancer: "cancer",
                    Leo: "leao",
                    Virgo: "virgem",
                    Libra: "libra",
                    Scorpio: "escorpiao",
                    Sagittarius: "sagitario",
                    Capricorn: "capricornio",
                    Aquarius: "aquario",
                    Pisces: "peixes"
                };
                return moonSignDictionary[sign] || sign
            },
            getMoonPhasesFormatted(currentMoonPhase) {
                switch (currentMoonPhase) {
                    case 'Waxing crescent':
                        this.moonPhasesPath = 'lua-crescente';
                        return 'Lua Crescente';
                        break;
                    case 'First quarter':
                        this.moonPhasesPath = 'lua-minguante';
                        return 'Lua Minguante';
                        break;
                    case 'Waxing gibbous':
                        this.moonPhasesPath = 'lua-crescente';
                        return 'Lua Crescente';
                        break;
                    case 'Last quarter':
                        this.moonPhasesPath = 'lua-minguante';
                        return 'Lua Minguante';
                        break;
                    case 'NEW':
                        this.moonPhasesPath = 'lua-nova';
                        return 'Lua Nova';
                        break;
                    case 'FULL':
                        this.moonPhasesPath = 'lua-cheia';
                        return 'Lua Cheia';
                        break;
                    default:
                        this.moonPhasesPath = 'lua-crescente';
                        return 'Lua Crescente';
                        break
                }
            },
            getMoonPhase(date) {
                const phase = ["new", "waxingCrescent", "firstQuarter", "waxingGibbous", "full", "waningGibbous",
                    "lastQuarter", "waningCrescent"
                ];
                const daysSinceNew = (date.getTime() - new Date("2024-06-26T00:00:00Z").getTime()) / (24 * 60 * 60 *
                    1000);
                const phaseIndex = Math.floor(daysSinceNew % 29.53 / 3.69);
                return phase[phaseIndex]
            },
            handleButtonSignClick(signo, event) {

                this.h2 = false;

                const buttons = document.querySelectorAll('.bs5829');
                buttons.forEach(button => {
                    button.classList.remove('active');
                });

                // Adicione a classe 'active' ao botão clicado
                event.currentTarget.classList.add('active');

                this.signValue = signo;
                //this.buttonActiveSign();
                this.handleClickMoonSign();
            },
            generatorDates() {
                switch (this.signValue) {
                    case "Áries":
                        this.daysList(2, 21, 19);
                        break;
                    case "Touro":
                        this.daysList(3, 20, 20);
                        break;
                    case "Gêmeos":
                        this.daysList(4, 21, 21);
                        break;
                    case "Câncer":
                        this.daysList(5, 22, 22);
                        break;
                    case "Leão":
                        this.daysList(6, 23, 22);
                        break;
                    case "Virgem":
                        this.daysList(7, 23, 22);
                        break;
                    case "Libra":
                        this.daysList(8, 23, 22);
                        break;
                    case "Escorpião":
                        this.daysList(9, 23, 21);
                        break;
                    case "Sagitário":
                        this.daysList(10, 22, 21);
                        break;
                    case "Capricórnio":
                        this.daysList(11, 22, 19);
                        break;
                    case "Aquário":
                        this.daysList(0, 20, 18);
                        break;
                    case "Peixes":
                        this.daysList(1, 19, 20);
                        break;
                    default:
                        break;
                }
            },
            daysList(month, startDay, endDay) {
                const currentYear = new Date().getFullYear();
                const startDate = new Date(currentYear, month, startDay);
                const nextMonth = month === 11 ? 0 : month + 1;
                const endDate = new Date(currentYear, nextMonth, endDay);

                this.listDays(startDate.getMonth(), startDate.getDate(), "start");
                this.listDays(endDate.getMonth(), endDate.getDate(), "end");
            },
            listDays(month, days, type) {
                const monthName = this.getMonthName(month);
                if (type === "start") {

                    const startDaysField = document.querySelector(".id2938 .dt948389 ul");
                    this.startDateField = monthName.name;
                    this.varListInitialDays = [];
                    for (let i = days; i <= monthName.days; i++) {
                        this.varListInitialDays.push(i);
                    }
                }

                const endDaysField = document.querySelector(".id784738 .dt948389 ul");
                this.endDateField = monthName.name;
                this.varListEndDays = [];
                for (let i = 1; i <= days; i++) {
                    this.varListEndDays.push(i);
                }
            },
            handleDayClick(day, type, month) {
                let selectedDayElement = document.querySelector(`li[data-value='${day}']`);
                if (selectedDayElement.classList.contains("active")) {
                    selectedDayElement.classList.remove("active");
                    this.monthValue = undefined;
                    this.monthNameValue = undefined;
                    this.dayValue = null;
                    return;
                }

                if(month == 'Janeiro'){
                    this.monthValue = 0;
                }else if(month == 'Fevereiro'){
                    this.monthValue = 1;
                }
                else if(month == 'Março'){
                    this.monthValue = 2;
                }
                else if(month == 'Abril'){
                    this.monthValue = 3;
                }
                else if(month == 'Maio'){
                    this.monthValue = 4;
                }
                else if(month == 'Junho'){
                    this.monthValue = 5;
                }
                else if(month == 'Julho'){
                    this.monthValue = 6;
                }
                else if(month == 'Agosto'){
                    this.monthValue = 7;
                }
                else if(month == 'Setembro'){
                    this.monthValue = 8;
                }
                else if(month == 'Outubro'){
                    this.monthValue = 9;
                }
                else if(month == 'Novembro'){
                    this.monthValue = 10;
                }
                else if(month == 'Dezembro'){
                    this.monthValue = 11;
                }

                this.monthNameValue = type === 'start' ? this.startDateField : this.endDateField;
                this.monthValue = this.monthValue;
                this.dayValue = day;
                selectedDayElement.classList.add("active");
                this.dayActiver();
                this.handleClickMoonSign();
            },
            dayActiver() {
                const startOptions = document.querySelectorAll(".start-days option");
                const endOptions = document.querySelectorAll(".end-days option");
                const allOptions = [...startOptions, ...endOptions];
                allOptions.forEach(option => {
                    if (Number(option.getAttribute("data-value")) === this.dayValue) {
                        option.classList.add("active");
                    } else {
                        option.classList.remove("active");
                    }
                });
            },
            getMonthName(month) {
                let monthObj = {};
                switch (month) {
                    case 0:
                        monthObj.name = "Janeiro";
                        monthObj.days = 31;
                        return monthObj;
                    case 1:
                        monthObj.name = "Fevereiro";
                        monthObj.days = 29;
                        return monthObj;
                    case 2:
                        monthObj.name = "Março";
                        monthObj.days = 31;
                        return monthObj;
                    case 3:
                        monthObj.name = "Abril";
                        monthObj.days = 30;
                        return monthObj;
                    case 4:
                        monthObj.name = "Maio";
                        monthObj.days = 31;
                        return monthObj;
                    case 5:
                        monthObj.name = "Junho";
                        monthObj.days = 30;
                        return monthObj;
                    case 6:
                        monthObj.name = "Julho";
                        monthObj.days = 31;
                        return monthObj;
                    case 7:
                        monthObj.name = "Agosto";
                        monthObj.days = 31;
                        return monthObj;
                    case 8:
                        monthObj.name = "Setembro";
                        monthObj.days = 30;
                        return monthObj;
                    case 9:
                        monthObj.name = "Outubro";
                        monthObj.days = 31;
                        return monthObj;
                    case 10:
                        monthObj.name = "Novembro";
                        monthObj.days = 30;
                        return monthObj;
                    case 11:
                        monthObj.name = "Dezembro";
                        monthObj.days = 31;
                        return monthObj;
                    default:
                        break;
                }
            },
            generatorDecade() {
                const currentYear = new Date().getFullYear() - 10;
                const decades = [];
                for (let i = 1910; i <= currentYear; i += 10) {
                    decades.push(i);
                    this.lastDecade = i;
                }
                this.decades = decades;
            },
            handleDecadeClick(decade) {
                if (this.decadeValue === decade) {
                    
                } else {
                    this.decadeValue = decade;
                }
                this.handleDecadeActive();
                this.handleClickMoonSign();
            },
            handleDecadeActive() {
                const decadeOptions = document.querySelectorAll(".decade-option");
                decadeOptions.forEach(option => {
                    if (Number(option.getAttribute("data-value")) === this.decadeValue) {
                        option.classList.add("active");
                    } else {
                        option.classList.remove("active");
                    }
                });
            },
            generatorYears() {
                const currentYear = new Date().getFullYear() - 10;
                const currentYearLastDigit = Number(String(currentYear).slice(2));
                const startYear = currentYearLastDigit > 10 ? currentYearLastDigit - 9 : currentYearLastDigit === 10 ?
                    0 : currentYearLastDigit;
                const endYear = this.decadeValue === this.lastDecade ? this.decadeValue + startYear : this.decadeValue +
                    10;

                const years = [];
                for (let i = this.decadeValue; i < endYear; i++) {
                    years.push(i);
                }
                this.years = years;
            },
            handleYearClick(year) {
                if (this.yearValue === year) {

                } else {
                    this.yearValue = year;
                }
                this.handleYearActive();
                this.handleClickMoonSign();
            },
            handleYearActive() {
                const yearOptions = document.querySelectorAll(".o132921");
                yearOptions.forEach(option => {
                    if (Number(option.getAttribute("data-value")) === this.yearValue) {
                        option.classList.add("active");
                    } else {
                        option.classList.remove("active");
                    }
                });
            },
            backBornGringo(){
                this.countrySelectorIsTrue = false;
                this.isCountryModalActive = false;
                this.countryValue = "";

                const askCountry = document.querySelector(".cq194839829328");
                const selectorCountry = document.querySelector(".clc1328919388");

                askCountry.classList.remove("hide");
                selectorCountry.classList.add("hide");
            },
            backPreencherHoras(){
                const formContainer = document.querySelector(".stf3132189319 .hqt13812938198");
                const moonSignButton = document.querySelector(".stf3132189319 .hbc12391949");

                formContainer.classList.remove("hide");
                moonSignButton.classList.add("hide");
            },
            stepFiveQuestion(bool) {
                const formContainer = document.querySelector(".stf3132189319 .hqt13812938198");
                const moonSignButton = document.querySelector(".stf3132189319 .hbc12391949");
                const backButton = document.querySelector(".stf3132189319 .hbc12391949 .step-hours-vl43829");

                if (bool == 'Sim') {
                    this.hoursFieldIsTrue = true;
                    formContainer.classList.add("hide");
                    moonSignButton.classList.remove("hide");
                } else {
                    this.handleClickMoonSign();
                }

                backButton.onclick = () => {
                    this.hoursFieldIsTrue = false;
                    formContainer.classList.remove("hide");
                    moonSignButton.classList.add("hide");
                };
            },
            toggleModal(type) {
                const modal = document.querySelector(`.${type}-modal`);
                modal.classList.toggle("active");
            },
            handleModalActive(options, field, value) {
                Array.from(options).forEach(option => {
                    if (option.getAttribute("data-value") == value) {
                    option.classList.add("active");
                    return;
                    }
                    option.classList.remove("active");
                    setTimeout(() => {
                    field.classList.remove("active");
                    }, 500);
                });
            },
            handleMinutesModal() {
                const minutesModal = document.querySelector(".minutes-modal");
                const minutesModalHead = document.querySelector(".minutes-modal .minutes-modal-head");
                const minutesField = document.querySelector(".minutes-modal #minutes-field");
                const minutesList = document.querySelector(".minutes-modal .modal-minutes-list");
                let minutesArray = [];

                for (let i = 0; i <= 60; i++) {
                    minutesArray.push(i);
                }

                let minutesElements = minutesArray.map((minute) => {
                    const li = document.createElement("li");
                    const minuteString = String(minute);
                    const formattedMinute = minute < 10 ? ("0" + minuteString) : minuteString;
                    li.setAttribute("data-value", formattedMinute);
                    li.innerHTML = formattedMinute;
                    return li;
                });

                minutesElements.forEach((minuteElement) => {
                    minutesList.appendChild(minuteElement);
                    minuteElement.onclick = () => {
                    if (minuteElement.classList.contains("active")) {
                        minutesField.value = "";
                        this.minutesValue = undefined;
                        minuteElement.classList.remove("active");
                        return;
                    }
                    minutesField.value = minuteElement.getAttribute("data-value");
                    this.minutesValue = minuteElement.getAttribute("data-value");
                    this.handleModalActive(minutesList.childNodes, minutesModal, this.minutesValue);
                    };
                });
            },
            handleHoursModal() {
                const hoursModal = document.querySelector(".hours-modal");
                const hoursModalHead = document.querySelector(".hours-modal .hours-modal-head");
                const hoursField = document.querySelector(".hours-modal #hours-field");
                const hoursList = document.querySelector(".hours-modal .modal-hours-list");
                let hoursArray = [];

                for (let i = 0; i <= 23; i++) {
                    hoursArray.push(i);
                }

                let hoursElements = hoursArray.map((hour) => {
                    const li = document.createElement("li");
                    const hourString = String(hour);
                    const formattedHour = hour < 10 ? ("0" + hourString) : hourString;
                    li.setAttribute("data-value", formattedHour);
                    li.innerHTML = formattedHour;
                    return li;
                });

                hoursElements.forEach((hourElement) => {
                    hoursList.appendChild(hourElement);
                    hourElement.onclick = () => {
                    if (hourElement.classList.contains("active")) {
                        hoursField.value = "";
                        this.hoursValue = undefined;
                        hourElement.classList.remove("active");
                        return;
                    }
                    hoursField.value = hourElement.getAttribute("data-value");
                    this.hoursValue = hourElement.getAttribute("data-value");
                    this.handleModalActive(hoursList.childNodes, hoursModal, this.hoursValue);
                    };
                });
            },


            confirmHours(){
                if (this.selectedHour && this.selectedMinute) {
                    this.hoursBirthdate = this.selectedHour + ":" + this.selectedMinute;
                    this.handleClickMoonSign();
                    return;
                }
                alert("Preencha a hora completa");
                this.hoursBirthdate = undefined;
            },
            clickHoursModalHead(){
                const hoursModal = document.querySelector(".hours-modal");
                if (hoursModal.classList.contains("active")) {
                    hoursModal.classList.remove("active");
                    return;
                }
                hoursModal.classList.add("active");
            },
            handleButtonHoursConfirmn() {
                if (!this.hoursValue && !this.minutesValue) {
                    alert("Please fill in both hours and minutes.");
                    this.hoursBirthdate = undefined;
                }
                this.hoursBirthdate = this.hoursValue + ":" + this.minutesValue;
                this.handleClickMoonSign();
            },
            clickCountryButtonTrue(){
                this.countryValue = "Brasil";
                    this.handleClickMoonSign();
            },
            clickCountryButtonFalse(){
                this.countrySelectorIsTrue = true;
                this.isCountryModalActive = false;
                this.toggleCountryModal();
            },
            toggleCountryModal() {
                this.isCountryModalActive = !this.isCountryModalActive;
            },
            selectCountry(country) {
                this.countryValue = country.value;
                this.isCountryModalActive = false;
                this.handleClickMoonSign();
            },
            handleCountryModal() {
                const countryModal = document.querySelector(".cm4999d338388");
                const countryModalHead = document.querySelector(".cm4999d338388 .cm4999d338388-head");
                const countryField = document.querySelector(".cm4999d338388 #c14134ff423432");
                const countryListItems = document.querySelectorAll(".cm4999d338388 .cl41031000438830000 li");

                countryModalHead.onclick = () => {
                    if (countryModal.classList.contains("active")) {
                        countryModal.classList.remove("active");
                        return;
                    }
                    countryModal.classList.add("active");
                };

                countryListItems.forEach((countryItem) => {
                    countryItem.onclick = () => {
                        if (countryItem.classList.contains("active")) {
                            countryField.value = "";
                            this.countryValue = undefined;
                            countryItem.classList.remove("active");
                            return;
                        }
                        countryField.value = countryItem.getAttribute("data-value");
                        this.countryValue = countryItem.getAttribute("data-value");
                        this.handleClickMoonSign();
                        this.handleCountryActive(countryListItems, countryModal);
                    };
                });
            },
            handleCountryActive(countryListItems, countryModal) {
                countryListItems.forEach((countryItem) => {
                    if (countryItem.getAttribute("data-value") == this.countryValue) {
                        countryItem.classList.add("active");
                        return;
                    }
                    countryItem.classList.remove("active");
                    setTimeout(() => {
                        countryModal.classList.remove("active");
                    }, 500);
                });
            },

            goToParte2() {
                this.moonPhasesStepTwo();
            },

            moonPhasesStepOne() {
                this.parteDoisSexo = false;
                this.parteDoisEstadoCivil = true;

                this.MoonPhasesStep = 2;
                this.moonPhasesCivilStatus();
            },

            moonPhasesStepTwo() {
                if (!this.selectedStatus) {
                    alert("Please select your civil status.");
                    return false;
                }
                this.parte2 = false;
                this.FormMoonPhasesIsCompleted();
            },
            async FormMoonPhasesIsCompleted() {

                this.showPlayer = true;

                const audioPath = "../audio/astrology/p2/p2-" + this.moonPhasesPath + ".mp3";
                const animationPath = "../animations/astrology/p2/p2-" + this.moonPhasesPath + ".json";
                const legendPath = "../legends/astrology/p2/p2-" + this.moonPhasesPath + ".vtt";
                const jsonData = await this.getJsonData(animationPath);

                if (jsonData) {
                    this.elements.sectionAnimation.classList.remove("hide");
                    this.elements.audioSection.classList.remove("hide");
                    this.generatorAnimation(jsonData);
                }

                this.handleVideo(audioPath, legendPath, this.elements.audioElement);
                this.handleStartVideo();
                
            },
            moonPhasesGender(gender) {
                this.parteDoisSexo = true;
            },
            clickButtonGender(gender) {

                if (gender != null || gender != '') {
                    this.genderValue = gender;
                    this.moonPhasesStepOne();
                }

            },
            backEstadoCivil(){
                this.parteDoisSexo = true;
                this.parteDoisEstadoCivil = false;
            },
            moonPhasesCivilStatus() {
                this.selectedStatus = status;
                this.modalActive = false;
            },

            genderActive(options) {
                options.forEach(option => {
                    if (option.getAttribute("data-value") === this.genderValue) {
                        option.classList.add("active");
                        return;
                    }
                    option.classList.remove("active");
                });
            },
            civilStatusActive(options) {
                options.forEach(option => {
                    if (option.getAttribute("data-value") === this.civilStatusValue) {
                        option.classList.add("active");
                        return;
                    }
                    option.classList.remove("active");
                });
            },

            validateEmail() {
                const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

                if (!emailPattern.test(this.emailValue)) {
                this.emailErrorMessage = "Formato de email inválido.";
                
                } else {

                    const url = `https://brevo.mahilaluz.workers.dev?email=${encodeURIComponent(this.emailValue)}&name=${encodeURIComponent(this.firstNameValue)}`;

                    try {
                        const response = axios.post(url);
                        console.log('', response.data);
                    } catch (error) {
                        console.error('.', error);
                    }
                    this.emailErrorMessage = "";
                    this.formMoonReadingCompleted();
                }
            },
            async formMoonReadingCompleted() {
                this.parte3 = false;
                this.showPlayer = true;
                let genderPath = this.genderValue === "masculino" ? "h" : "m";
                let civilStatusChar = this.getCharCivilStatus(this.civilStatusValue);
                let ageFormatted = this.getAgeUserFormat(this.ageUserValue);

                const audioPath = "../audio/astrology/p3/" + genderPath + "/p3-" + genderPath + "-" + ageFormatted +
                    "-" + civilStatusChar + ".mp3";
                const animationPath = "../animations/astrology/p3/" + genderPath + "/p3-" + genderPath + "-" +
                    ageFormatted + "-" + civilStatusChar + ".json";
                const legendPath = "../legends/astrology/p3/" + genderPath + "/p3-" + genderPath + "-" +
                    ageFormatted + "-" + civilStatusChar + ".vtt";
                const jsonData = await this.getJsonData(animationPath);

                //this.activeFormThird();

                if (jsonData) {
                    this.elements.sectionAnimation.classList.remove("hide");
                    this.elements.audioSection.classList.remove("hide");
                    this.generatorAnimation(jsonData);
                }

                this.handleVideo(audioPath, legendPath, this.elements.audioElement);
                this.handleStartVideo();
            },
            async handleModalPitch() {
                this.parte4 = false;
                this.showPlayer = true;
                const audioPath = "../audio/astrology/p4/p4.mp3";
                const animationPath = "../animations/astrology/p4/p4.json";
                const legendPath = "../legends/astrology/p4/p4.vtt";
                const jsonData = await this.getJsonData(animationPath);

                if (jsonData) {
                    this.elements.sectionAnimation.classList.remove("hide");
                    this.elements.audioSection.classList.remove("hide");
                    await this.handleVideo(audioPath, legendPath, this.elements.audioElement);
                    await this.handleStartVideo();
                    this.generatorAnimation(jsonData);
                }

                
                //this.activeFormFourth();
            },
            getCharCivilStatus(civilStatus) {
                if (civilStatus === "Solteiro(a)" || civilStatus === "Separado(a)" || civilStatus === "Viúvo(a)") {
                    return "s";
                }
                if (civilStatus === "Casado(a)" || civilStatus === "Namorando" || civilStatus === "Noivo(a)") {
                    return "c";
                }
                return "s";
            },
            getAgeUser() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth();
                const currentDay = currentDate.getDate();
                let age = currentYear - this.yearValue;
                if (this.monthValue > currentMonth) {
                    age -= 1;
                }
                if (this.monthValue === currentMonth) {
                    if (this.dayValue > currentDay) {
                        age -= 1;
                    }
                    return age;
                }
                return age;
            },
            getAgeUserFormat(age) {
                if (age < 20) {
                    return "20";
                } else if (age >= 20 && age < 30) {
                    return "30";
                } else if (age >= 30 && age < 40) {
                    return "40";
                } else if (age >= 40 && age < 50) {
                    return "50";
                } else if (age >= 50 && age < 60) {
                    return "60";
                }
                return "60";
            },
            alert(message) {
                const alertBox = document.createElement("div");
                alertBox.classList.add("alert-message");
                const alertHTML = `<div class="alert-content">${message}</div>`;
                alertBox.innerHTML = alertHTML;
                document.body.append(alertBox);
                setTimeout(() => {
                    alertBox.remove();
                }, 2000);
            },

            handlePlayVideo() {
                if (this.elements.buttonPlayAudio.classList.contains("active")) {
                    this.playerVideo.pause();
                    this.animationTimeLine.pause();
                    this.elements.buttonPlayAudio.classList.remove("active");
                    this.elements.buttonPlayAudio.classList.add("paused");
                    return;
                }
                this.playerVideo.play();
                this.animationTimeLine.play();
                this.elements.buttonPlayAudio.classList.add("active");
                this.elements.buttonPlayAudio.classList.remove("paused");
            },

            replaceContent(content, highlight) {

                if(this.moonSign == 'aries'){
                    this.moonSignCerto = 'Áries'
                } else if(this.moonSign == 'touro'){
                    this.moonSignCerto = 'Touro'
                }
                else if(this.moonSign == 'gemeos'){
                    this.moonSignCerto = 'Gêmeos'
                }
                else if(this.moonSign == 'leao'){
                    this.moonSignCerto = 'Leão'
                }
                else if(this.moonSign == 'virgem'){
                    this.moonSignCerto = 'Virgem'
                }
                else if(this.moonSign == 'libra'){
                    this.moonSignCerto = 'Libra'
                }
                else if(this.moonSign == 'escorpiao'){
                    this.moonSignCerto = 'Escorpião'
                }
                else if(this.moonSign == 'sagitario'){
                    this.moonSignCerto = 'Sagitário'
                }
                else if(this.moonSign == 'capricornio'){
                    this.moonSignCerto = 'Capricórnio'
                }
                else if(this.moonSign == 'aquario'){
                    this.moonSignCerto = 'Aquário'
                }
                else if(this.moonSign == 'peixes'){
                    this.moonSignCerto = 'Peixes'
                }


                const replacements = [{
                        "[nome]": highlight ? `<b>${this.firstNameValue}</b>` : this.firstNameValue
                    },
                    {
                        "[data-nascimento]": highlight ? `<b>${this.dateValue}</b>` : this.dateValue
                    },
                    {
                        "[signo]": highlight ? `<b>${this.signValue}</b>` : this.signValue
                    },
                    {
                        "[email]": highlight ? `<b>${this.emailValue}</b>` : this.emailValue
                    },
                    {
                        "[signo-lua]": highlight ? `<b>${this.moonSignCerto}</b>` : this.moonSignCerto
                    },
                    {
                        "[fase-lua]": highlight ? `<b>${this.moonPhases}</b>` : this.moonPhases
                    }
                ];
                let replacedContent = content;
                replacements.forEach(replacement => {
                    let [key, value] = Object.entries(replacement)[0];
                    if (typeof replacedContent === "string" || replacedContent instanceof String) {
                        replacedContent = replacedContent.replace(key, value);
                    }
                });
                return replacedContent;
            },
            handleCta(section) {
                const target = section;

                if (String(target) == '[cta]') {
                    this.cta = true;
                }

                this.elements.sectionAnimation.classList.add('hide');
                this.elements.modalElement.innerHTML = '';
                this.animationTimeLine.kill();
            },
            formIsTrue(section) {

                const target = section;

                if(String(target) == '[form2]'){
                    this.parte2 = true;
                    this.showPlayer = false;
                }else if (String(target) == '[form3]') {
                    this.parte3 = true;
                    this.showPlayer = false;
                }else if (String(target) == '[form4]') {
                    this.parte4 = true;
                    this.showPlayer = false;
                }else if (String(target) == '[cta]') {
                    this.cta = true;
                }

                this.elements.sectionAnimation.classList.add('hide');

                this.elements.modalElement.innerHTML = '';

                this.animationTimeLine.kill();
            },
            async handleStartVideo(autoStart) {
                this.playerVideo.on('ended', () => {
                    return this.elements.audioSection.classList.add('hide');
                });

                this.elements.buttonPlayAudio.classList.remove('active');
                this.elements.buttonPlayAudio.classList.add('false');
                this.elements.buttonVolumeAudio.classList.add('active');
                this.elements.buttonVolumeAudio.classList.remove('false');

                this.playerVideo.volume(1);

                if (!autoStart) {
                    setTimeout(() => {
                        this.handlePlayVideo();
                    }, 1500);
                    return;
                }

                const modalWarning = document.querySelector('.modal-warning');
                const btnPlay = document.querySelector('.modal-warning .btn-play');

                btnPlay.onclick = () => {
                    modalWarning.classList.add('hide');
                    //this.elements.audioLoop.loop = true;
                    //this.elements.audioLoop.volume = 0.1;
                    //this.elements.audioLoop.play();
                    this.handlePlayVideo();
                }
            },

            /*window.addEventListener("DOMContentLoaded", function () {
                noSleep.enable();
            });*/

            activeFormThird() {
                /*const xhr = new XMLHttpRequest();
                let formattedDate = new Date(dateValue).toLocaleDateString('en-GB', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                }).replace(/\//g, "-");
                const formData = [
                    ["first-name", firstNameValue],
                    ["email", emailValue],
                    ["gender", genderValue],
                    ["sign", signValue],
                    ["moon-sign", moonSign],
                    ["civil-status", civilStatusValue],
                    ["moon-phases", moonPhases],
                    ["birth-date", formattedDate]
                ];
                let queryString = "";
                formData.forEach((pair, index) => {
                    queryString += pair.join("=") + (index == 0 ? "&" : "");
                });
                fetch("/submit?" + queryString, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json"
                    }
                });*/
            },

            activeFormFourth() {
                /*const xhr = new XMLHttpRequest();
                const formData = {
                    email: emailValue
                };
                fetch("/submit?" + new URLSearchParams(formData), {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json"
                    }
                });*/
            },
            cardText(content, imgPath = pathCard) {
                const div = document.createElement('div');
                div.classList.add('frame');
                div.style =
                    "opacity: 0; aspect-ratio: 3/4; display: flex; align-items: center; justify-content: center; position: absolute; filter: drop-shadow(0px 1px 3px #9a9a9a);";

                if (window.innerWidth <= 1400 && window.innerWidth > 768) {
                    div.style.width = "130px";
                } else if (window.innerWidth <= 768) {
                    div.style.width = "150px";
                } else {
                    div.style.width = "100%";
                }

                const img = new Image();
                img.style = "aspect-ratio: 3/4; width: 100%; position: absolute; z-index: -1;";
                img.src = imgPath;

                const span =
                    `<span style='position: absolute; font-size: 1rem; text-transform: uppercase; width: 100%; left: 0; height: 100%; top: 0; display: flex; align-items: center; justify-content: center; text-shadow: 2px 0px 5px black;'>${content}</span>`;

                div.appendChild(img);
                div.innerHTML += span;

                return div;
            },

            framePhrase(primaryText, secondaryText) {
                const frame = document.createElement('div');
                frame.classList.add('frame');

                if (window.innerWidth <= 768) {
                    frame.style += "aspect-ratio: 3/4;";
                } else {
                    frame.style += "width: 100%; position: absolute; z-index: -1;";
                }

                let content = `<span>${primaryText}</span>`;
                if (secondaryText) {
                    content += `<span>${secondaryText}</span>`;
                }

                frame.innerHTML = content;

                return frame;
            },

            newCard(content) {
                const img = new Image();
                img.src = pathCard;
                img.style = "aspect-ratio: 3/4; width: 100%; position: absolute; z-index: -1;";

                const div = document.createElement('div');
                div.classList.add('frame', 'card');
                div.style =
                    "opacity: 0; aspect-ratio: 3/4; display: flex; align-items: center; justify-content: center; position: absolute; filter: drop-shadow(0px 1px 3px #9a9a9a);";

                if (window.innerWidth <= 1400 && window.innerWidth > 768) {
                    div.style.width = "130px";
                } else if (window.innerWidth <= 768) {
                    div.style.width = "150px";
                } else {
                    div.style.width = "100%";
                }

                div.appendChild(img);

                if (content.length === 2) {
                    const texts = content.split(' ');
                    div.innerHTML += `<div><span>${texts[0]}</span><span>${texts[1]}</span></div>`;
                } else if (content.length === 4) {
                    const texts = content.split(' ');
                    div.style.aspectRatio = "4/3";
                    div.innerHTML +=
                        `<div><span>${texts[0]}</span><span>${texts[1]}</span><span>${texts[2]}</span><span>${texts[3]}</span></div>`;
                } else {
                    div.innerHTML += `<div><span>${content}</span></div>`;
                }

                return div;
            },

            newElementText(content) {
                const div = document.createElement('p');
                div.classList.add('frame');
                div.style += 'transition: 1s; opacity: 0; position: absolute; margin: 0; max-width: 100%; width: 100%;';
                const span = ('<span>' + content + '</span>');
                div.innerHTML = span;
                return div
            },

            newElementTitle(content) {
                const div = document.createElement('p');
                div.classList.add('frame');
                div.style +=
                    'transition: 1s; color: #fff; width: fit-content; opacity: 0; position: absolute; margin: 0; width: fit-content; position: absolute;';
                if (window.innerWidth <= 500) {
                    div.style.fontSize = '1.25rem'
                };
                const span = ('<span>' + content + '</span>');
                div.innerHTML = span;
                return div
            },

            listGenerator(items = []) {
                const ul = document.createElement('ul');
                ul.classList.add('frame');
                ul.classList.add('list');
                ul.style +=
                    'margin: 0px; list-style: none; height: 0; width: 100%; padding: 0px; max-width: 100%; display: flex; flex-direction: column; justify-content: flex-start;';
                const item = items.map((item) => {
                    const li = ("<li style='margin-bottom: 10px;display: inline-block;'>" + item + "</li>");
                    ul.innerHTML += li
                });
                return ul
            },

            newImage(src, alt, title) {
                const img = new Image();
                img.src = src;
                img.classList.add('frame');
                img.classList.add('image');
                img.style = 'position: absolute;max-width: 100%;opacity: 0;';
                img.style.width = ("" + (alt ? alt : 'auto') + "");
                img.style.height = ("" + (title ? title : 'auto') + "");
                return img
            },

            animationFrame(element, animation) {
                animation = animation;
                animation.pause();
                if (element.type !== "list_text" && element.type !== "form" && element.type !== "data_layer") {
                    const newElement = this.createElement(element.content, element.type, element.style);
                    if (element.style) {
                        for (const styleKey in element.style) {
                            newElement.style[styleKey] = element.style[styleKey]
                        }
                    };

                    if (element.type === "image") {
                        newElement.addEventListener("load", () => {
                            element.efects.forEach((item, index) => {
                                this.animationConstructor(item, newElement, animation, element, index,
                                    element.moving, element.dataLayer, element.glowUp)
                            })
                        })
                    };

                    this.handleAddChild(newElement, element.display);
                    element.efects.forEach((item, index) => {
                        this.animationConstructor(item, newElement, animation, index, element.moving, element
                            .dataLayer, element.glowUp)
                    })
                } else {
                    if (element.type === "form") {
                        this.handleDisplayForm(element.content, element.time, animation)
                    } else {
                        if (element.type === "data_layer") {
                            const formElement = document.createElement("span");
                            this.elements.modalElement.appendChild(formElement);
                            animation.to(formElement, {
                                onComplete: () => {
                                    dataLayer.push(element.dataLayer)
                                }
                            }, element.time)
                        } else {
                            const itemNames = element.items.map((item) => {
                                return item.nameItem;
                            });
                            const createdElement = createElement(itemNames, element.type);
                            if (element.style) {
                                for (const styleKey in element.style) {
                                    createdElement.style[styleKey] = element.style[styleKey]
                                }
                            };
                            this.elements.modalElement.appendChild(createdElement);
                            const childNodes = createdElement.childNodes;
                            element.items.forEach((element, index) => {
                                element.efects.forEach((item) => {
                                    this.animationConstructor(item, childNodes[index], animation, index,
                                        element.moving, element.dataLayer, element.glowUp)
                                })
                            })
                        }
                    }
                }
            },

            handleAddChild(createdElement, position) {
                if (position) {
                    if (position === "left" && window.innerWidth <= 500) {
                        this.elements.modalElement.appendChild(createdElement);
                    } else if (position === "right" && window.innerWidth > 500) {
                        this.elements.modalElement.appendChild(createdElement);
                    } else if (position === "center" && window.innerWidth > 768 && window.innerWidth < 1200) {
                        this.elements.modalElement.appendChild(createdElement);
                    }
                } else {
                    this.elements.modalElement.appendChild(createdElement);
                }
            },

            createElement(content, type) {
                switch (type) {
                    case "text":
                        return this.newElementText(this.replaceContent(content));
                    case "phrase":
                        return this.framePhrase(this.replaceContent(content[0]), this.replaceContent(content[1]));
                    case "list":
                        return this.listGenerator(this.replaceContent(content));
                    case "title":
                        return this.newElementTitle(this.replaceContent(content));
                    case "card":
                        return this.newCard(this.replaceContent(content));
                    case "cardText":
                        return this.cardText(this.replaceContent(content));
                    case "image":
                        return this.newImage(this.replaceContent(content));
                    default:
                        break;
                }
            },

            handleDisplayForm(formName, sections, animation) {
                var _this = this;
                this.formsSectionsArray.forEach((section) => {
                    if (formName === section.name) {
                        if (section.name === '[cta]') {
                            animation.to(section.section, { onComplete: () => _this.handleCta(section.name) }, sections);
                            return
                        };
                        animation.to(section.section, { onComplete: () => _this.formIsTrue(section.name) }, sections);
                    }
                })
            },

            animationConstructor(element, target, animation, index, callback, data, modal) {

                let startPosition, endPosition, animationStart, animationEnd;
                let elementAnimation = element.from_animation;
                let elementOptions = element.to_animation;
                if (modal) {
                    target.classList.add('glowUp')
                };
                if (window.innerWidth <= 768) {
                    animationStart = 10
                } else {
                    animationStart = 30
                };
                const positions = [{
                    name: 'top_center',
                    values: {
                        x: this.modelVW / 2 - target.clientWidth / 2,
                        y: animationStart
                    }
                }, {
                    name: 'top_left',
                    values: {
                        x: animationStart,
                        y: animationStart
                    }
                }, {
                    name: 'top_left_center',
                    values: {
                        x: this.modelVW / 3 - target.clientWidth / 3,
                        y: animationStart
                    }
                }, {
                    name: 'top_right',
                    values: {
                        x: this.modelVW - target.clientWidth - animationStart,
                        y: animationStart
                    }
                }, {
                    name: 'top_right_center',
                    values: {
                        x: this.modelVW / 1.5 - target.clientWidth / 1.5 + 10,
                        y: animationStart
                    }
                }, {
                    name: 'center',
                    values: {
                        x: this.modelVW / 2 - target.clientWidth / 2,
                        y: this.modelVH / 2 - target.clientHeight / 2
                    }
                }, {
                    name: 'center_left',
                    values: {
                        x: animationStart,
                        y: this.modelVH / 2 - target.clientHeight / 2
                    }
                }, {
                    name: 'center_left_center',
                    values: {
                        x: this.modelVW / 3 - target.clientWidth / 3,
                        y: this.modelVH / 2 - target.clientHeight / 2
                    }
                }, {
                    name: 'center_right',
                    values: {
                        x: this.modelVW - target.clientWidth - animationStart,
                        y: this.modelVH / 2 - target.clientHeight / 2
                    }
                }, {
                    name: 'center_right_center',
                    values: {
                        x: this.modelVW / 1.5 - target.clientWidth / 1.5 + 10,
                        y: this.modelVH / 2 - target.clientHeight / 2
                    }
                }, {
                    name: 'bottom_center',
                    values: {
                        x: this.modelVW / 2 - target.clientWidth / 2,
                        y: this.modelVH - target.clientHeight - animationStart
                    }
                }, {
                    name: 'bottom_left',
                    values: {
                        x: this.modelVW - target.clientWidth - animationStart,
                        y: this.modelVH - target.clientHeight - animationStart
                    }
                }, {
                    name: 'bottom_left_center',
                    values: {
                        x: this.modelVW / 1.5 - target.clientWidth / 1.5 + 10,
                        y: this.modelVH - target.clientHeight - animationStart
                    }
                }, {
                    name: 'bottom_right',
                    values: {
                        x: animationStart,
                        y: this.modelVH - target.clientHeight - animationStart
                    }
                }, {
                    name: 'bottom_right_center',
                    values: {
                        x: this.modelVW / 3 - target.clientWidth / 3,
                        y: this.modelVH - target.clientHeight - animationStart
                    }
                }, {
                    name: 'center_center_top',
                    values: {
                        x: this.modelVW / 2 - target.clientWidth / 2,
                        y: this.modelVH / 3 - target.clientHeight / 3
                    }
                }, {
                    name: 'center_center_top_left',
                    values: {
                        x: animationStart,
                        y: this.modelVH / 3 - target.clientHeight / 3
                    }
                }, {
                    name: 'center_center_top_right',
                    values: {
                        x: this.modelVW - target.clientWidth - animationStart,
                        y: this.modelVH / 3 - target.clientHeight / 3
                    }
                }, {
                    name: 'center_center_bottom',
                    values: {
                        x: this.modelVW / 2 - target.clientWidth / 2,
                        y: this.modelVH / 1.3 - target.clientHeight / 1.3
                    }
                }, {
                    name: 'center_center_bottom_left',
                    values: {
                        x: animationStart,
                        y: this.modelVH / 1.3 - target.clientHeight / 1.3
                    }
                }, {
                    name: 'center_center_bottom_right',
                    values: {
                        x: this.modelVW - target.clientWidth - animationStart,
                        y: this.modelVH / 1.3 - target.clientHeight / 1.3
                    }
                }];
                if (element.position_from) {
                    const start = positions.filter((pos) => {
                        if (pos.name === element.position_from) {
                            return pos
                        }
                    });
                    startPosition = start[0].values;
                    elementAnimation = {
                        ...elementAnimation,
                        ...startPosition,
                        ...animationEnd
                    }
                };
                if (element.position_to) {
                    const start = positions.filter((pos) => {
                        if (pos.name === element.position_to) {
                            return pos
                        }
                    });
                    endPosition = start[0].values;
                    elementOptions = {
                        ...elementOptions,
                        ...endPosition
                    }
                };
                if (element.type_animation === 'fromTo') {
                    if (data || callback && index === 0) {
                        animation.fromTo(target, element.duration, elementAnimation, {
                            ...elementOptions,
                            onComplete: callback && data ? () => {

                                const loop = new TimelineMax({
                                    repeat: -1
                                });
                                loop.to(target, 1, {
                                    scale: 1.050
                                });
                                loop.to(target, 1, {
                                    scale: 1
                                });
                                dataLayer.push(data);
                            } : callback ? this.handleMoving : () => {
                                dataLayer.push(data)
                            }
                        }, element.time)
                    } else {
                        animation.fromTo(target, element.duration, elementAnimation, elementOptions, element.time)
                    }
                } else {
                    if (element.type_animation === 'to') {
                        animation.to(target, element.duration, elementOptions, element.time)
                    } else {
                        animation.from(target, element.duration, elementAnimation, element.time)
                    }
                }
            },

            handleMoving() {
                const loop = new TimelineMax({
                    repeat: -1
                });
                this._targets = document.querySelectorAll('img.frame.image');

                this._targets.forEach(target => {
                    const loop = new TimelineMax({
                        repeat: -1,
                        yoyo: true
                    });
                    loop.to(target, 1, {
                        scale: 1.050,
                        ease: Power1.easeInOut
                    });
                });
            },

            /*handleGlowUP(target) {
                const loop = new TimelineMax({
                    repeat: -1
                });
                loop.to(target, 1, {
                    css: {
                        filter: "drop-shadow(0 0 0.375rem rgb(255, 255, 255, 0.3))"
                    }
                });
                loop.to(target, 1, {
                    css: {
                        filter: "drop-shadow(0 0 0 rgb(255, 255, 255, 0.3))"
                    }
                });
            },

            miniCards(cardTitles, cardContents, useIcons) {
                const container = document.createElement('div');
                const containerStyle = window.innerWidth <= 500 ? "minicard_small" : "minicard_large";
                container.style =
                    `position: relative; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; width: 100%; gap: 10px;`;

                const iconClasses = ["icon1", "icon2", "icon3", "icon4", "icon5"];
                const iconTemplate = window.innerWidth <= 500 ? "small_icon" : "large_icon";
                const cardTemplate = window.innerWidth <= 500 ? "card_template_small" : "card_template_large";

                cardTitles.forEach((title, index) => {
                    if (useIcons) {
                        if (title !== "special") {
                            const sanitizedTitle = title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                .toLowerCase();
                            if (iconClasses.includes(sanitizedTitle)) {
                                const icon = `<div class="${iconTemplate}"><span>${title}</span></div>`;
                                const content =
                                    `<div class="${cardTemplate}"><span>${cardContents[index]}</span></div>`;
                                const card = document.createElement('div');
                                card.innerHTML += icon;
                                card.innerHTML += content;
                                container.appendChild(card);
                            } else {
                                const defaultCard = `<div class="${cardTemplate}"><span>${title}</span></div>`;
                                container.innerHTML += defaultCard;
                            }
                        } else {
                            if (window.innerWidth <= 500) {
                                const specialIcon = "<div class='special_small'></div>";
                                container.innerHTML += specialIcon;
                            } else {
                                const specialIcon = "<div class='special_large'></div>";
                                container.innerHTML += specialIcon;
                            }
                        }
                    } else {
                        if (title !== "special") {
                            const titleCard = `<div class="${cardTemplate}"><span>${title}</span></div>`;
                            const contentCard =
                                `<div class="${cardTemplate}"><span>${cardContents[index]}</span></div>`;
                            const card = document.createElement('div');
                            card.innerHTML += titleCard;
                            card.innerHTML += contentCard;
                            container.appendChild(card);
                        } else {
                            if (window.innerWidth <= 500) {
                                const specialIcon = "<div class='special_small'></div>";
                                container.innerHTML += specialIcon;
                            } else {
                                const specialIcon = "<div class='special_large'></div>";
                                container.innerHTML += specialIcon;
                            }
                        }
                    }
                });

                return container;
            },*/

            normalize(v) {
                v = v - Math.floor(v);
                if (v < 0) {
                    v = v + 1;
                }
                return v;
            },


            getMoonInformations(date) {
                var age, // Moon's age
                    distance, // Moon's distance in earth radii
                    latitude, // Moon's ecliptic latitude
                    longitude, // Moon's ecliptic longitude
                    phase, // Moon's phase
                    trajectory, // Moon's trajectory
                    zodiac; // Moon's zodiac sign 

                var yy, mm, k1, k2, k3, jd;
                var ip, dp, np, rp;

                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var day = date.getDate();


                yy = year - Math.floor((12 - month) / 10);
                mm = month + 9;
                if (mm >= 12) {
                    mm = mm - 12;
                }

                k1 = Math.floor(365.25 * (yy + 4712));
                k2 = Math.floor(30.6 * mm + 0.5);
                k3 = Math.floor(Math.floor((yy / 100) + 49) * 0.75) - 38;

                jd = k1 + k2 + day + 59; // for dates in Julian calendar
                if (jd > 2299160) {
                    jd = jd - k3; // for Gregorian calendar
                }

                //calculate moon's age in days
                ip = this.normalize((jd - 2451550.1) / 29.530588853);
                age = ip * 29.53;

                if (age < 1.84566) {
                    phase = 'NEW';
                    trajectory = 'ascendent';
                } else if (age < 5.53699) {
                    phase = 'Waxing crescent';
                    trajectory = 'ascendent';
                } else if (age < 9.22831) {
                    phase = 'First quarter';
                    trajectory = 'ascendent';
                } else if (age < 12.91963) {
                    phase = 'Waxing gibbous';
                    trajectory = 'ascendent';
                } else if (age < 16.61096) {
                    phase = 'FULL';
                    trajectory = 'descendent';
                } else if (age < 20.30228) {
                    phase = 'Waning gibbous';
                    trajectory = 'descendent';
                } else if (age < 23.99361) {
                    phase = 'Last quarter';
                    trajectory = 'descendent';
                } else if (age < 27.68493) {
                    phase = 'Waning crescent';
                    trajectory = 'descendent';
                } else {
                    phase = 'NEW';
                    trajectory = 'ascendent';
                }

                ip = ip * 2 * Math.PI; //Convert phase to radians

                // Calculate moon's distance
                dp = 2 * Math.PI * this.normalize((jd - 2451562.2) / 27.55454988);
                distance = 60.4 - 3.3 * Math.cos(dp) - 0.6 * Math.cos(2 * ip - dp) - 0.5 * Math.cos(2 * ip);

                // Calculate moon's ecliptic latitude
                np = 2 * Math.PI * this.normalize((jd - 2451565.2) / 27.212220817);
                latitude = 5.1 * Math.sin(np);

                // Calculate moon's ecliptic longitude
                rp = this.normalize((jd - 2451555.8) / 27.321582241);
                longitude = 360 * rp + 6.3 * Math.sin(dp) + 1.3 * Math.sin(2 * ip - dp) + 0.7 * Math.sin(2 * ip);

                if (longitude < 33.18) {
                    zodiac = 'Pisces';
                } else if (longitude < 51.16) {
                    zodiac = 'Aries';
                } else if (longitude < 93.44) {
                    zodiac = 'Taurus';
                } else if (longitude < 119.48) {
                    zodiac = 'Gemini';
                } else if (longitude < 135.30) {
                    zodiac = 'Cancer';
                } else if (longitude < 173.34) {
                    zodiac = 'Leo';
                } else if (longitude < 224.17) {
                    zodiac = 'Virgo';
                } else if (longitude < 242.57) {
                    zodiac = 'Libra';
                } else if (longitude < 271.26) {
                    zodiac = 'Scorpio';
                } else if (longitude < 302.49) {
                    zodiac = 'Sagittarius';
                } else if (longitude < 311.72) {
                    zodiac = 'Capricorn';
                } else if (longitude < 348.58) {
                    zodiac = 'Aquarius';
                } else {
                    zodiac = 'Pisces';
                }

                return {
                    'date': {
                        'year': year,
                        'month': month,
                        'day': day
                    },
                    'age': age,
                    'distance': distance * 6371,
                    'ecliptic': {
                        'latitude': latitude,
                        'longitude': longitude
                    },
                    'phase': phase,
                    'trajectory': trajectory,
                    'constellation': zodiac,
                };
            },
            datasForDay(day) {
                return this.getMoonInformations(day);
            },
            fetchMoonData() {
                this.moonData = this.datasForDay(new Date());
            },
            async generatorAnimation(animationData) {
                this.animationTimeLine = gsap.timeline().pause();
                this.animationTimeLine.pause;

                this.modelVH = this.elements.modalElement.clientHeight;
                this.modelVW = this.elements.modalElement.clientWidth;

                animationData.forEach((animationFrameData) => {
                    this.animationFrame(animationFrameData, this.animationTimeLine);
                });
            },
            async handleVideo(videoSrc, textTrackSrc, modalElement, replaceContent) {
                modalElement.load();
                var isTextTrackAdded = false;
                let videoConfig = {
                    controls: false,
                    autoplay: false,
                    fluid: true,
                    preload: "auto",
                    sources: [{
                        src: videoSrc,
                        type: "audio/mp3"
                    }]
                };

                if (!this.playerVideo) {
                    this.playerVideo = videojs("my-player", videoConfig);
                }

                this.playerVideo.src({
                    type: "audio/mp3",
                    src: videoSrc
                });

                this.playerVideo.ready(() => {
                    var textTracks = this.playerVideo.remoteTextTracks();
                    var numTextTracks = textTracks.length;
                    while (numTextTracks--) {
                        this.playerVideo.removeRemoteTextTrack(textTracks[numTextTracks]);
                    }
                    this.playerVideo.addRemoteTextTrack({
                        kind: "captions",
                        src: textTrackSrc,
                        mode: "showing"
                    }, false);
                });

                var _this = this;

                this.playerVideo.on("play", () => {
                    if (!isTextTrackAdded) {
                        var textTracks = this.playerVideo.textTracks();

                        var track = textTracks[
                        0]; // Assumindo que textTracks é acessível e possui pelo menos um track

                        // Adiciona um ouvinte de evento ao track, não ao cue individual
                        track.addEventListener("cuechange", function () {
                            // Este evento é acionado quando a lista de cues ativos é atualizada
                            var activeCues = track.activeCues; // Obtém os cues ativos atualmente
                            if (activeCues.length > 0) {
                                for (var i = 0; i < activeCues.length; i++) {
                                    // Modifica o texto do cue conforme necessário
                                    activeCues[i].text = _this.replaceContent(activeCues[i].text, true);
                                }
                            }
                        });

                        isTextTrackAdded = true;
                    }
                });

                this.playerVideo.pause();
            },

            async getJsonData(url) {
                let jsonData;
                await fetch(`/${url}/`, {
                        method: "GET",
                        headers: {
                            'Accept': "application/json"
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        jsonData = data;
                    });
                return jsonData;
            },

            handleFormsSections(sections) {
                this.formsSectionsArray = sections;
            }



        }
    }
</script>