<style>
.progress-container {
    width: 100%;
    background-color: #ddd;
    height: 10px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}
</style>
<template lang="br">
    <div id="page-wrapper" class="page-wrapper">
        <div class="background-image-sign">
            <img v-if="backgroundVSL" src="../assets/imgs/astrology/dark-bg-wide.jpg" alt="">
        </div>
        <div class="container">
            <div class="progress-container">
                <div class="progress-bar" :style="{width: progress + '%'}" style="border-radius: 0px; background-color: rgb(255 0 156); height: 100%; width: 0%; transition: width 0.6s ease; max-width: 100%"></div>
            </div>
            <div class="ga4983248 container">
                <section class="eh90427" v-if="section1">
                    <div class="hf-4920">
                        <h2 v-if="currentStep === 1" style="margin-bottom: 20px; color: #000; font-size: 1.4rem">SEU FUTURO REVELADO: <span style="color: rgb(255 0 156)">LEITURA ASTROLÓGICA GRÁTIS</span></h2>
                        <br v-if="currentStep !== 1"><br v-if="currentStep !== 1">
                        <p v-if="currentStep === 1" class="section-p" style="text-align: center; font-family: 'Sora', sans-serif; font-size: 17px; line-height: 1.3em; color: #060606">
                            Sua leitura astrológica 100% personalizada revela previsões futuras e explora os segredos profundos para uma vida melhor no <span
                                style="text-transform: normal; color: #fff; font-family: 'Sora', sans-serif" class="gradient-text">Amor, Saúde,
                                Felicidade e Finanças!</span>
                        </p>
                    </div>
                    <div class="form-modal">
                        <div class="form-container">
                            <div class="ms1 form-content" v-if="currentStep === 1">
                                <h3
                                    style="font-family: Sora, sans-serif; font-size: 19px; font-weight: 500; color: #060606; border-radius: 4px; padding: 4px; margin: 0px">
                                    PASSO 1: Toque no SEU SIGNO abaixo</h3>
                                <br />
                                <div class="sbc1945">
                                    <button class="bs5829" @click="handleButtonSignClick('Áries', $event)"><img src="../assets/imgs/astrology/i-aries-desk.png" loading="lazy" width="59" height="50">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            ÁRIES<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">21/03
                                                - 19/04</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Touro', $event)"><img src="../assets/imgs/astrology/i-touro-desk.png" width="59" height="50" loading="lazy">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            TOURO<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">20/04
                                                - 20/05</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Gêmeos', $event)"><img src="../assets/imgs/astrology/i-gemeos-desk.png" width="32" height="50" loading="lazy">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            GÊMEOS<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">21/05
                                                - 21/06</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Câncer', $event)"><img src="../assets/imgs/astrology/i-cancer-desk.png" width="40" height="50" loading="lazy">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            CÂNCER<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">22/06
                                                - 22/07</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Leão', $event)"><img src="../assets/imgs/astrology/i-leao-desk.png" width="54" height="50" loading="lazy">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            LEÃO<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">23/07
                                                - 22/08</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Virgem', $event)"><img src="../assets/imgs/astrology/i-virgem-desk.png" width="54" height="50" loading="lazy">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            VIRGEM<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">23/08
                                                - 22/09</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Libra', $event)"><img src="../assets/imgs/astrology/i-libra-desk.png" width="39" height="50" loading="lazy">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            LIBRA<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">23/09
                                                - 22/10</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Escorpião', $event)"><img src="../assets/imgs/astrology/i-escorpiao-desk.png" width="60" height="50" loading="lazy">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            ESCORPIÃO<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">23/10
                                                - 21/11</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Sagitário', $event)"><img src="../assets/imgs/astrology/i-sagitario-desk.png" width="64" height="50" loading="lazy">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            SAGITÁRIO<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">22/11
                                                - 21/12</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Capricórnio', $event)"><img src="../assets/imgs/astrology/i-capricornio-desk.png" width="50" height="50" loading="lazy">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            CAPRICÓRNIO<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">22/12
                                                - 19/01</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Aquário', $event)"><img src="../assets/imgs/astrology/i-aquario-desk.png" width="47" height="50" loading="lazy">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            AQUÁRIO<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">20/01
                                                - 18/02</span></p>
                                    </button>
                                    <button class="bs5829" @click="handleButtonSignClick('Peixes', $event)"><img src="../assets/imgs/astrology/i-peixes-desk.png" width="57" height="50" loading="lazy">
                                        <p
                                            style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 18px;margin: 0px;color: purple; margin-top: 10px">
                                            PEIXES<br /><span
                                                style="text-align: center; font-family: 'Sora', serif; font-weight: 400; font-size: 14px; color: purple; margin-top: 5px">19/02
                                                - 20/03</span></p>
                                    </button>
                                </div>
                                <br>
                                <h3
                                    style="font-family: Sora, sans-serif; font-size: 26px; font-weight: 500; color: #060606; border-radius: 4px; padding: 4px; margin: 0px">
                                    PASSO 1: Toque no SEU SIGNO acima</h3>
                                <br />

                            </div>
                            <div class="form-content" v-if="currentStep === 2">
                                <h3>Qual seu Dia de Nascimento?</h3>
                                <div class="dsc698">
                                    <div class="id2938 dc48194">
                                        <div class="header31283">
                                            <h4 style="margin: 0px;">{{startDateField}}</h4>
                                        </div>
                                        <div class="dt948389">
                                            <ul class="ls318329">
                                                <li v-for="day in varListInitialDays" :data-value="day"
                                                    @click="handleDayClick(day, 'start', startDateField)">
                                                    {{ day }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="id784738 dc48194">
                                        <div class="header31283">
                                            <h4 style="margin: 0px;">{{endDateField}}</h4>
                                        </div>
                                        <div class="dt948389">
                                            <ul class="ls318329">
                                                <li v-for="day in varListEndDays" :data-value="day"
                                                    @click="handleDayClick(day, 'end', endDateField)">
                                                    {{ day }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <button class=" vl43829" @click="handleBackBtnMoonSign()"
                                    style="display:block;text-align:center; margin-top: 30px;"><img
                                        src="../assets/imgs/white-back.png" style="height: 50px;"></button>
                            </div>
                            <div class="form-content" v-if="currentStep === 3">
                                <h3>Em qual Década você nasceu?</h3>
                                
                                <div class="dc48438">
                                    <ul class="ls318329">
                                        <li v-for="decade in decades" :key="decade" :data-value="decade"
                                            @click="handleDecadeClick(decade)"
                                            :class="{'btn-item': true, 'decade-option': true, 'active': decade === decadeValue}">
                                            {{ decade }}
                                        </li>
                                    </ul>
                                </div>
                                <button class=" vl43829" @click="handleBackBtnMoonSign()"
                                    style="display:block;text-align:center; margin-top: 30px;"><img
                                        src="../assets/imgs/white-back.png" style="height: 50px;"></button>
                            </div>
                            <div class="form-content" v-if="currentStep === 4">
                                <h3>Em que Ano você nasceu?</span></h3>
                                <div class="yc238192448329">
                                    <ul class="ls318329">
                                        <li v-for="year in years" :key="year" :data-value="year"
                                            @click="handleYearClick(year)"
                                            :class="{'o132921': true, 'active': year === yearValue, 'btn-item': true}">
                                            {{ year }}
                                        </li>
                                    </ul>
                                </div>
                                <button class=" vl43829" @click="handleBackBtnMoonSign()"
                                    style="display:block;text-align:center; margin-top: 30px;"><img
                                        src="../assets/imgs/white-back.png" style="height: 50px;"></button>
                            </div>
                            <div class="stf3132189319 form-content" v-if="currentStep === 5">
                                <div class="hqt13812938198 ">
                                    <h3>Você lembra a Hora em que nasceu?</span></h3>

                                    <div class="btc38912312989">
                                        <button class="b2das289914" style="background: #19a10b !important"
                                            @click="stepFiveQuestion('Sim')">Sim</button>
                                        <button class="b2das289914" style="background: #e32121 !important"
                                            @click="stepFiveQuestion('Não')">Não</button>
                                    </div>
                                    <button class=" vl43829" @click="handleBackBtnMoonSign()"
                                        style="display:block;text-align:center; margin-top: 30px;"><img
                                            src="../assets/imgs/white-back.png" style="height: 50px;"></button>
                                </div>
                                <div class="hbc12391949 hide">
                                    <h3>Preencha a hora do seu nascimento</h3>
                                    <div class="input-control hf143154331">
                                        <div class="mhc12310">
                                            <div class="select-modal mwc13213100700" style="display: flex;flex-direction: row;column-gap: 20px;">
                                              <select v-model="selectedHour" class="form-control">
                                                <option value="" disabled selected>Horas</option>
                                                <option v-for="hour in hourOptions" :value="hour">{{ hour }}</option>
                                              </select>
                                              <select v-model="selectedMinute" class="form-control">
                                                <option value="" disabled selected>Minutos</option>
                                                <option v-for="minute in minuteOptions" :value="minute">{{ minute }}</option>
                                              </select>
                                            </div>
                                            <button class="btn-hours-confirm" @click="confirmHours">Confirmar</button>
                                        </div>
                                    </div>
                                    <button class="2312312314ddd-42-vl43829 vl43829" @click="backPreencherHoras()"
                                        style="display:block;text-align:center; bottom: -130px !important;"><img
                                            src="../assets/imgs/white-back.png" style="height: 50px;"></button>
                                </div>
                            </div>
                            <div class="sl31239199998773343" v-if="currentStep === 5.5">
                                <h3 style="color: #060606">Personalizando a sua leitura...</h3>
                                <div class="progress-bar">
                                    <div class="progress-fill"></div>
                                </div>
                            </div>
                            <div class="sx13320008666 form-content" v-if="currentStep === 6">
                                <div class="cq194839829328" v-if="!countrySelectorIsTrue">
                                  <h3>Você nasceu no Brasil?</h3>
                                  <div class="bmc123800488 btc38912312989">
                                    <button class="b2das289914" @click="clickCountryButtonTrue" style="background: #19a10b !important">Sim</button>
                                    <button class="b2das289914" @click="clickCountryButtonFalse" style="background: #e32121 !important">Não</button>
                                  </div>
                                  <button class=" vl43829" @click="handleBackBtnMoonSign()" style="display:block;text-align:center; margin-top: 30px;">
                                    <img src="../assets/imgs/white-back.png" style="height: 50px;" />
                                  </button>
                                </div>
                                <div class="clc1328919388" v-if="countrySelectorIsTrue">
                                  <h3>Em qual país você nasceu?</h3>
                                  <div class="cm4999d338388 mwc13213100700">
                                    <div class="mwh31902190 cm4999d338388-head">
                                      <input type="text" id="c14134ff423432" placeholder="Qual o seu país" disabled class="c14134ff423432" v-model="countryValue">
                                      <div class="mi139102000">
                                        <i class="fas fa-chevron-down"></i>
                                      </div>
                                    </div>
                                    <ul class="cl41031000438830000 mwlk123910020000354699" style="display: block !important" v-show="isCountryModalActive">
                                      <li v-for="country in countries" :key="country.value" :data-value="country.value" @click="selectCountry(country)">
                                        <img :src="country.flag" alt="">
                                        <span>{{ country.name }}</span>
                                      </li>
                                    </ul>
                                  </div>
                                  <button class=" vl43829" @click="backBornGringo()" style="display:block;text-align:center; margin-top: 30px;">
                                    <img src="../assets/imgs/white-back.png" style="height: 50px;" />
                                  </button>
                                </div>
                            </div>
                            <div class="s7s1238s7sd30007600000 form-content" v-if="currentStep === 7">
                                <div class="input-control ffn312312900nbn432420000">
                                    <h3>Qual é o seu Primeiro Nome?</span></h3>
                                    <div class="">
                                        <input type="text" id="f131238918284" value="" v-model="firstName" @change="handleFieldFirstName" class="field-input" placeholder="Digite seu nome" autocomplete="given-name">
                                        <div class="error-field" v-html="errorFieldMessage"></div>
                                    </div>
                                </div>
                                <div class="form-button">
                                    <button class="button-form" @click="handleClickMoonSign()" style="background: #19a10b !important">Clique
                                        Aqui Para Continuar!</button>
                                </div><br /><br />
                                <button class="vl43829" style="display:block;text-align:center; margin-top: 30px;" @click="handleBackBtnMoonSign()"><img
                                    src="../assets/imgs/white-back.png" style="height: 50px;"></button>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="animation-section hide">
                    <div class="animation-container">
                        <div class="play-animation modal-warning">
                            <div class="content-warning">
                                <h3><br><span style="color: #ffdb60"></span>, sua leitura vai sair do ar em breve.</h3>
                                <img src="https://video.astranumerica.com.br/assets/imgs/stop.png?token=c1xInIi5_xcmFEuooSjaOKx7NvB0-VarLC7iBEC-ZJk&expires=1719662181"
                                    />
                                <p>Essa é a sua última chance de assistir até o final. <br>
                                    Clique no botão abaixo…</p>
                            </div>
                            <button class="btn-play" style="background: #19a10b !important" @click="continuarVideo(textoWarning)">{{textoWarning}}</button>
                        </div>
                        <div class="modal-animation">
                            <div class="modal-audio-container" style="color: white"></div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div class="loading-container" v-if="loadingBlock">
            <h3 style="color: white">Carregando a sua leitura astrológica....</h3>
            <div class="progress-bar">
                <div class="progress-fill"></div>
            </div>
        </div>
        <!--<audio loop="true" class="audio-loop">
            <source src="../assets/audio/fundo-loop-novo.mp3" />
        </audio>-->
    </div>
</template>

<script src="../js/video.min.js"></script>
<script src="../js/astrology/moonCalc.js?y=827"></script>
<script src="../js/global.js"></script>

<script>
    import NoSleep from 'nosleep.js';
    import axios from 'axios';

    export default {
        name: 'TesteVSLNova',
        data() {
            return {
                progress: 0,
                selectedHour: "",
                selectedMinute: "",
                hourOptions: [],
                minuteOptions: [],
                currentStep: 1,
                noSleep: new NoSleep(),
                noSleepEnabled: false,
                elements: {},
                signValue: null,
                moonSign: null,
                moonPhases: null,
                moonPhasesPath: null,
                moonSignStep: 1,
                MoonPhasesStep: 1,
                monthValue: null,
                monthNameValue: null,
                dayValue: null,
                decadeValue: null,
                yearValue: null,
                dateValue: null,
                ageUserValue: null,
                hoursFieldIsTrue: false,
                hoursBirthdate: null,
                hoursValue: undefined,
                minutesValue: undefined,
                countryValue: null,
                firstName: '',
                errorFieldMessage: '',
                firstNameValue: undefined,
                countrySelectorIsTrue: false,
                isCountryModalActive: false,
                countries: [
                    { name: "Argentina", value: "Argentina", flag: "../imgs/astrology/flags/ar.svg" },
                    { name: "Canadá", value: "Canadá", flag: "../imgs/astrology/flags/ca.svg" },
                    { name: "Chile", value: "Chile", flag: "../imgs/astrology/flags/cl.svg" },
                    { name: "Colômbia", value: "Colômbia", flag: "../imgs/astrology/flags/co.svg" },
                    { name: "Equador", value: "Equador", flag: "../imgs/astrology/flags/ec.svg" },
                    { name: "Espanha", value: "Espanha", flag: "../imgs/astrology/flags/es.svg" },
                    { name: "Japão", value: "Japão", flag: "../imgs/astrology/flags/jp.svg" },
                    { name: "Paraguay", value: "Paraguay", flag: "../imgs/astrology/flags/pr.svg" },
                    { name: "Portugal", value: "Portugal", flag: "../imgs/astrology/flags/pt.svg" },
                    { name: "EUA", value: "EUA", flag: "../imgs/astrology/flags/us.svg" },
                    { name: "Uruguay", value: "Uruguay", flag: "../imgs/astrology/flags/uy.svg" },
                    { name: "Outro", value: "Outro", flag: "" }
                ],
                firstNameValue: null,
                lastDecade: null,
                srcAudio: null,
                formsSectionsArray: [],
                activeSign: null,
                startDateField: null,
                endDateField: null,
                varListInitialDays: [],
                varListEndDays: [],
                decades: [],
                decadeValue: null,
                lastDecade: null,
                years: [],
                yearValue: null,
                pauseIcon: false,
                textoWarning: 'COMEÇAR',
                backgroundVSL: false,
                h2: true,
                showPlayer: false,
                loadingBlock: false,
                section1: true,
                usuario: []
            };
        },
        mounted() {
            this.generateHourOptions();
            this.generateMinuteOptions();

            this.noSleep.enable(); // Ativa o NoSleep
            this.noSleepEnabled = true;
        },
        methods: {
            updateProgress(step) {
                if (step === 1) {
                    this.progress = 50;
                } else if (step === 2) {
                    this.progress = 75;
                } else if (step === 3) {
                    this.progress = 85;
                } else if (step === 4) {
                    this.progress = 95;
                }
            },
            handleBackBtnMoonSign() {
                if (this.currentStep > 1) {
                    this.currentStep--;
                }
            },
            generateHourOptions() {
                let hours = [];
                for (let hour = 0; hour < 24; hour++) {
                    const formattedHour = hour < 10 ? `0${hour}` : hour;
                    hours.push(formattedHour);
                }
                this.hourOptions = hours;
            },
            generateMinuteOptions() {
                let minutes = [];
                for (let minute = 0; minute < 60; minute++) {
                    const formattedMinute = minute < 10 ? `0${minute}` : minute;
                    minutes.push(formattedMinute);
                }
                this.minuteOptions = minutes;
            },
            handleClickMoonSign() {
                switch (this.currentStep) {
                    case 1:
                        this.handlestepOneMoonSign();
                        this.updateProgress(1);
                        break;
                    case 2:
                        this.handlestepTwoMoonSign();
                        this.updateProgress(2);
                        break;
                    case 3:
                        this.handlestepThirdMoonSign();
                        this.updateProgress(3);
                        break;
                    case 4:
                        this.handleStepFourthMoonSign();
                        break;
                    case 5:
                        this.handleStepFiveMoonSign();
                        break;
                    case 6:
                        this.handleStepSixMoonSign();
                        this.updateProgress(4);
                        break;
                    default:
                        this.handleStepSeventhMoonSign();
                        break
                }
            },
            handlestepOneMoonSign() {
                if (!this.signValue) {
                    alert("Por favor selecione seu signo!");
                    return false
                }
                this.currentStep = 2;
                this.generatorDates();
            },
            handlestepTwoMoonSign() {
                if (!this.dayValue) {
                    alert("Por favor selecione sua data de nascimento!");
                    return false
                }
                this.currentStep = 3;
                this.generatorDecade()
            },
            handlestepThirdMoonSign() {
                if (!this.decadeValue) {
                    alert("Por favor selecione sua década de nascimento!");
                    return false
                }
                this.currentStep = 4;
                this.generatorYears()
            },
            handleStepFourthMoonSign() {
                if (!this.yearValue) {
                    alert("Por favor selecione seu ano de nascimento!");
                    return false
                }
                this.currentStep = 5;
            },
            handleStepFiveMoonSign() {
                if (this.hoursFieldIsTrue && !this.hoursBirthdate) {
                    alert("Por favor selecione seu horário de nascimento!");
                    return
                }
                this.currentStep = 5.5;
                setTimeout(() => {
                    this.currentStep = 6;
                }, 2000);
            },
            handleStepSixMoonSign() {
                if (this.countrySelectorIsTrue && !this.countryValue) {
                    alert("Por favor selecione seu país de nascimento!");
                    return
                }
                this.currentStep = 7;
                this.handleFieldFirstName();
            },
            handleStepSeventhMoonSign() {
                if (!this.firstNameValue) {
                    alert("Campo do primeiro nome inválido!");
                    return;
                }
                const alertModal = document.querySelector(".modal-warning .content-warning h3 span");
                alertModal.innerHTML = this.firstNameValue;
                this.section1 = false;

                this.usuario.monthValue = this.monthValue;
                this.usuario.dayValue = this.dayValue;
                this.usuario.yearValue = this.yearValue;
                this.usuario.firstName = this.firstName;
                this.usuario.firstNameValue = this.firstNameValue;
                this.usuario.signValue = this.signValue;

                console.log(this.usuario);

                localStorage.setItem('usuario', JSON.stringify(this.usuario));
                this.$router.push({ name: 'MahilaTestePS', params: { usuario: this.usuario } });

                //this.FormMoonSignIsCompleted();
            },
            
            handleFieldFirstName() {
                const value = this.firstName;
                const numberPattern = /[0-9]/;
                const multipleNamesPattern = /[a-z]+[ ]+[a-z]/g;

                if (numberPattern.test(value)) {
                    this.errorFieldMessage = "*Apenas letras são permitidas!";
                    this.firstNameValue = undefined;
                    return;
                }
                if (multipleNamesPattern.test(value)) {
                    this.errorFieldMessage = "*Apenas o primeiro nome!";
                    this.firstNameValue = undefined;
                    return;
                }
                this.errorFieldMessage = "";
                const valueFormated = value.replace(/ /g, "");
                this.firstNameValue = valueFormated;
            },
            handleClickMoonPhases() {
                if (!this.moonPhasesPath) {
                    alert("O campo da fase lunar está vazio!");
                    return
                }
                const stepFiveMoonReading = document.querySelector(".sing-moon-reading-step-fifth");
                const stepFiveMoonPhases = document.querySelector(".sing-moon-reading-step-six");
                stepFiveMoonReading.classList.add("hide");
                stepFiveMoonPhases.classList.remove("hide");
                this.MoonPhasesStep = 6
            },
            async handleCurrentData(month, day, year) {
                return new Promise((resolve, reject) => {
                    var currentTime = new Date();
                    if (!this.hoursValue) this.hoursValue = currentTime.getHours();
                    if (!this.minutesValue) this.minutesValue = currentTime.getMinutes();
                    var d = new Date(year + "-" + month + "-" + day + " " + this.hoursValue + ":" + this
                        .minutesValue);
                    resolve({
                        moonPhase: this.getMoonPhase(d)
                    })
                    reject({

                    })
                })
            },
            handleMoonPhasesPath(currentMoonPhase) {
                return this.getMoonPhasesFormatted(currentMoonPhase)
            },
            continuarVideo(texto){
                if(texto == 'COMEÇAR'){
                    this.textoWarning = 'CONTINUAR';
                }

                this.pauseIcon = false;

            },
            pauseAudio() {
                if(this.pauseIcon == true){
                    this.pauseIcon = false;
                }else{
                    this.pauseIcon = true;
                }

                const modalWarning = document.querySelector('.modal-warning');

                modalWarning.classList.remove('hide');

                this.handlePlayVideo();
            },
            handleButtonSignClick(signo, event) {

                this.h2 = false;

                const buttons = document.querySelectorAll('.bs5829');
                buttons.forEach(button => {
                    button.classList.remove('active');
                });

                // Adicione a classe 'active' ao botão clicado
                event.currentTarget.classList.add('active');

                this.signValue = signo;
                //this.buttonActiveSign();
                this.handleClickMoonSign();
            },
            generatorDates() {
                switch (this.signValue) {
                    case "Áries":
                        this.daysList(2, 21, 19);
                        break;
                    case "Touro":
                        this.daysList(3, 20, 20);
                        break;
                    case "Gêmeos":
                        this.daysList(4, 21, 21);
                        break;
                    case "Câncer":
                        this.daysList(5, 22, 22);
                        break;
                    case "Leão":
                        this.daysList(6, 23, 22);
                        break;
                    case "Virgem":
                        this.daysList(7, 23, 22);
                        break;
                    case "Libra":
                        this.daysList(8, 23, 22);
                        break;
                    case "Escorpião":
                        this.daysList(9, 23, 21);
                        break;
                    case "Sagitário":
                        this.daysList(10, 22, 21);
                        break;
                    case "Capricórnio":
                        this.daysList(11, 22, 19);
                        break;
                    case "Aquário":
                        this.daysList(0, 20, 18);
                        break;
                    case "Peixes":
                        this.daysList(1, 19, 20);
                        break;
                    default:
                        break;
                }
            },
            daysList(month, startDay, endDay) {
                const currentYear = new Date().getFullYear();
                const startDate = new Date(currentYear, month, startDay);
                const nextMonth = month === 11 ? 0 : month + 1;
                const endDate = new Date(currentYear, nextMonth, endDay);

                this.listDays(startDate.getMonth(), startDate.getDate(), "start");
                this.listDays(endDate.getMonth(), endDate.getDate(), "end");
            },
            listDays(month, days, type) {
                const monthName = this.getMonthName(month);
                if (type === "start") {

                    const startDaysField = document.querySelector(".id2938 .dt948389 ul");
                    this.startDateField = monthName.name;
                    this.varListInitialDays = [];
                    for (let i = days; i <= monthName.days; i++) {
                        this.varListInitialDays.push(i);
                    }
                }

                const endDaysField = document.querySelector(".id784738 .dt948389 ul");
                this.endDateField = monthName.name;
                this.varListEndDays = [];
                for (let i = 1; i <= days; i++) {
                    this.varListEndDays.push(i);
                }
            },
            handleDayClick(day, type, month) {
                let selectedDayElement = document.querySelector(`li[data-value='${day}']`);
                if (selectedDayElement.classList.contains("active")) {
                    selectedDayElement.classList.remove("active");
                    this.monthValue = undefined;
                    this.monthNameValue = undefined;
                    this.dayValue = null;
                    return;
                }

                if(month == 'Janeiro'){
                    this.monthValue = 0;
                }else if(month == 'Fevereiro'){
                    this.monthValue = 1;
                }
                else if(month == 'Março'){
                    this.monthValue = 2;
                }
                else if(month == 'Abril'){
                    this.monthValue = 3;
                }
                else if(month == 'Maio'){
                    this.monthValue = 4;
                }
                else if(month == 'Junho'){
                    this.monthValue = 5;
                }
                else if(month == 'Julho'){
                    this.monthValue = 6;
                }
                else if(month == 'Agosto'){
                    this.monthValue = 7;
                }
                else if(month == 'Setembro'){
                    this.monthValue = 8;
                }
                else if(month == 'Outubro'){
                    this.monthValue = 9;
                }
                else if(month == 'Novembro'){
                    this.monthValue = 10;
                }
                else if(month == 'Dezembro'){
                    this.monthValue = 11;
                }

                this.monthNameValue = type === 'start' ? this.startDateField : this.endDateField;
                this.monthValue = this.monthValue;
                this.dayValue = day;
                selectedDayElement.classList.add("active");
                this.dayActiver();
                this.handleClickMoonSign();
            },
            dayActiver() {
                const startOptions = document.querySelectorAll(".start-days option");
                const endOptions = document.querySelectorAll(".end-days option");
                const allOptions = [...startOptions, ...endOptions];
                allOptions.forEach(option => {
                    if (Number(option.getAttribute("data-value")) === this.dayValue) {
                        option.classList.add("active");
                    } else {
                        option.classList.remove("active");
                    }
                });
            },
            getMonthName(month) {
                let monthObj = {};
                switch (month) {
                    case 0:
                        monthObj.name = "Janeiro";
                        monthObj.days = 31;
                        return monthObj;
                    case 1:
                        monthObj.name = "Fevereiro";
                        monthObj.days = 29;
                        return monthObj;
                    case 2:
                        monthObj.name = "Março";
                        monthObj.days = 31;
                        return monthObj;
                    case 3:
                        monthObj.name = "Abril";
                        monthObj.days = 30;
                        return monthObj;
                    case 4:
                        monthObj.name = "Maio";
                        monthObj.days = 31;
                        return monthObj;
                    case 5:
                        monthObj.name = "Junho";
                        monthObj.days = 30;
                        return monthObj;
                    case 6:
                        monthObj.name = "Julho";
                        monthObj.days = 31;
                        return monthObj;
                    case 7:
                        monthObj.name = "Agosto";
                        monthObj.days = 31;
                        return monthObj;
                    case 8:
                        monthObj.name = "Setembro";
                        monthObj.days = 30;
                        return monthObj;
                    case 9:
                        monthObj.name = "Outubro";
                        monthObj.days = 31;
                        return monthObj;
                    case 10:
                        monthObj.name = "Novembro";
                        monthObj.days = 30;
                        return monthObj;
                    case 11:
                        monthObj.name = "Dezembro";
                        monthObj.days = 31;
                        return monthObj;
                    default:
                        break;
                }
            },
            generatorDecade() {
                const currentYear = new Date().getFullYear() - 10;
                const decades = [];
                for (let i = 1910; i <= currentYear; i += 10) {
                    decades.push(i);
                    this.lastDecade = i;
                }
                this.decades = decades;
            },
            handleDecadeClick(decade) {
                if (this.decadeValue === decade) {
                    
                } else {
                    this.decadeValue = decade;
                }
                this.handleDecadeActive();
                this.handleClickMoonSign();
            },
            handleDecadeActive() {
                const decadeOptions = document.querySelectorAll(".decade-option");
                decadeOptions.forEach(option => {
                    if (Number(option.getAttribute("data-value")) === this.decadeValue) {
                        option.classList.add("active");
                    } else {
                        option.classList.remove("active");
                    }
                });
            },
            generatorYears() {
                const currentYear = new Date().getFullYear() - 10;
                const currentYearLastDigit = Number(String(currentYear).slice(2));
                const startYear = currentYearLastDigit > 10 ? currentYearLastDigit - 9 : currentYearLastDigit === 10 ?
                    0 : currentYearLastDigit;
                const endYear = this.decadeValue === this.lastDecade ? this.decadeValue + startYear : this.decadeValue +
                    10;

                const years = [];
                for (let i = this.decadeValue; i < endYear; i++) {
                    years.push(i);
                }
                this.years = years;
            },
            handleYearClick(year) {
                if (this.yearValue === year) {

                } else {
                    this.yearValue = year;
                }
                this.handleYearActive();
                this.handleClickMoonSign();
            },
            handleYearActive() {
                const yearOptions = document.querySelectorAll(".o132921");
                yearOptions.forEach(option => {
                    if (Number(option.getAttribute("data-value")) === this.yearValue) {
                        option.classList.add("active");
                    } else {
                        option.classList.remove("active");
                    }
                });
            },
            backBornGringo(){
                this.countrySelectorIsTrue = false;
                this.isCountryModalActive = false;
                this.countryValue = "";

                const askCountry = document.querySelector(".cq194839829328");
                const selectorCountry = document.querySelector(".clc1328919388");

                askCountry.classList.remove("hide");
                selectorCountry.classList.add("hide");
            },
            backPreencherHoras(){
                const formContainer = document.querySelector(".stf3132189319 .hqt13812938198");
                const moonSignButton = document.querySelector(".stf3132189319 .hbc12391949");

                formContainer.classList.remove("hide");
                moonSignButton.classList.add("hide");
            },
            stepFiveQuestion(bool) {
                const formContainer = document.querySelector(".stf3132189319 .hqt13812938198");
                const moonSignButton = document.querySelector(".stf3132189319 .hbc12391949");
                const backButton = document.querySelector(".stf3132189319 .hbc12391949 .step-hours-vl43829");

                if (bool == 'Sim') {
                    this.hoursFieldIsTrue = true;
                    formContainer.classList.add("hide");
                    moonSignButton.classList.remove("hide");
                } else {
                    this.handleClickMoonSign();
                }

                backButton.onclick = () => {
                    this.hoursFieldIsTrue = false;
                    formContainer.classList.remove("hide");
                    moonSignButton.classList.add("hide");
                };
            },
            toggleModal(type) {
                const modal = document.querySelector(`.${type}-modal`);
                modal.classList.toggle("active");
            },
            handleModalActive(options, field, value) {
                Array.from(options).forEach(option => {
                    if (option.getAttribute("data-value") == value) {
                    option.classList.add("active");
                    return;
                    }
                    option.classList.remove("active");
                    setTimeout(() => {
                    field.classList.remove("active");
                    }, 500);
                });
            },
            handleMinutesModal() {
                const minutesModal = document.querySelector(".minutes-modal");
                const minutesModalHead = document.querySelector(".minutes-modal .minutes-modal-head");
                const minutesField = document.querySelector(".minutes-modal #minutes-field");
                const minutesList = document.querySelector(".minutes-modal .modal-minutes-list");
                let minutesArray = [];

                for (let i = 0; i <= 60; i++) {
                    minutesArray.push(i);
                }

                let minutesElements = minutesArray.map((minute) => {
                    const li = document.createElement("li");
                    const minuteString = String(minute);
                    const formattedMinute = minute < 10 ? ("0" + minuteString) : minuteString;
                    li.setAttribute("data-value", formattedMinute);
                    li.innerHTML = formattedMinute;
                    return li;
                });

                minutesElements.forEach((minuteElement) => {
                    minutesList.appendChild(minuteElement);
                    minuteElement.onclick = () => {
                    if (minuteElement.classList.contains("active")) {
                        minutesField.value = "";
                        this.minutesValue = undefined;
                        minuteElement.classList.remove("active");
                        return;
                    }
                    minutesField.value = minuteElement.getAttribute("data-value");
                    this.minutesValue = minuteElement.getAttribute("data-value");
                    this.handleModalActive(minutesList.childNodes, minutesModal, this.minutesValue);
                    };
                });
            },
            handleHoursModal() {
                const hoursModal = document.querySelector(".hours-modal");
                const hoursModalHead = document.querySelector(".hours-modal .hours-modal-head");
                const hoursField = document.querySelector(".hours-modal #hours-field");
                const hoursList = document.querySelector(".hours-modal .modal-hours-list");
                let hoursArray = [];

                for (let i = 0; i <= 23; i++) {
                    hoursArray.push(i);
                }

                let hoursElements = hoursArray.map((hour) => {
                    const li = document.createElement("li");
                    const hourString = String(hour);
                    const formattedHour = hour < 10 ? ("0" + hourString) : hourString;
                    li.setAttribute("data-value", formattedHour);
                    li.innerHTML = formattedHour;
                    return li;
                });

                hoursElements.forEach((hourElement) => {
                    hoursList.appendChild(hourElement);
                    hourElement.onclick = () => {
                    if (hourElement.classList.contains("active")) {
                        hoursField.value = "";
                        this.hoursValue = undefined;
                        hourElement.classList.remove("active");
                        return;
                    }
                    hoursField.value = hourElement.getAttribute("data-value");
                    this.hoursValue = hourElement.getAttribute("data-value");
                    this.handleModalActive(hoursList.childNodes, hoursModal, this.hoursValue);
                    };
                });
            },


            confirmHours(){
                if (this.selectedHour && this.selectedMinute) {
                    this.hoursBirthdate = this.selectedHour + ":" + this.selectedMinute;
                    this.handleClickMoonSign();
                    return;
                }
                alert("Preencha a hora completa");
                this.hoursBirthdate = undefined;
            },
            clickHoursModalHead(){
                const hoursModal = document.querySelector(".hours-modal");
                if (hoursModal.classList.contains("active")) {
                    hoursModal.classList.remove("active");
                    return;
                }
                hoursModal.classList.add("active");
            },
            handleButtonHoursConfirmn() {
                if (!this.hoursValue && !this.minutesValue) {
                    alert("Please fill in both hours and minutes.");
                    this.hoursBirthdate = undefined;
                }
                this.hoursBirthdate = this.hoursValue + ":" + this.minutesValue;
                this.handleClickMoonSign();
            },
            clickCountryButtonTrue(){
                this.countryValue = "Brasil";
                    this.handleClickMoonSign();
            },
            clickCountryButtonFalse(){
                this.countrySelectorIsTrue = true;
                this.isCountryModalActive = false;
                this.toggleCountryModal();
            },
            toggleCountryModal() {
                this.isCountryModalActive = !this.isCountryModalActive;
            },
            selectCountry(country) {
                this.countryValue = country.value;
                this.isCountryModalActive = false;
                this.handleClickMoonSign();
            },
            handleCountryModal() {
                const countryModal = document.querySelector(".cm4999d338388");
                const countryModalHead = document.querySelector(".cm4999d338388 .cm4999d338388-head");
                const countryField = document.querySelector(".cm4999d338388 #c14134ff423432");
                const countryListItems = document.querySelectorAll(".cm4999d338388 .cl41031000438830000 li");

                countryModalHead.onclick = () => {
                    if (countryModal.classList.contains("active")) {
                        countryModal.classList.remove("active");
                        return;
                    }
                    countryModal.classList.add("active");
                };

                countryListItems.forEach((countryItem) => {
                    countryItem.onclick = () => {
                        if (countryItem.classList.contains("active")) {
                            countryField.value = "";
                            this.countryValue = undefined;
                            countryItem.classList.remove("active");
                            return;
                        }
                        countryField.value = countryItem.getAttribute("data-value");
                        this.countryValue = countryItem.getAttribute("data-value");
                        this.handleClickMoonSign();
                        this.handleCountryActive(countryListItems, countryModal);
                    };
                });
            },
            handleCountryActive(countryListItems, countryModal) {
                countryListItems.forEach((countryItem) => {
                    if (countryItem.getAttribute("data-value") == this.countryValue) {
                        countryItem.classList.add("active");
                        return;
                    }
                    countryItem.classList.remove("active");
                    setTimeout(() => {
                        countryModal.classList.remove("active");
                    }, 500);
                });
            },
        }
    }
</script>