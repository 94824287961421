<style>
@media print {

  body,
  element {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

pre {
  max-width: 100%;
  /* Limita a largura máxima do elemento <pre> */
  white-space: pre-wrap;
}

.avoid-page-break {
  page-break-inside: avoid;
  /* Evita quebras de página dentro do elemento */
}
</style>
<template>
  <div>
    <div class="container-fluid fundo-mahila">
      <div class="row">
        <div class="col-4"></div>
        <div class="col-sm-12 popup-mahila">
          <div style="
          display: flex;
          flex-direction: column;
          align-content: center;
          align-items: center;
      ">
            <h3 style="text-align: center; color: red; font-weight: bold">Última Chance: você acabou de receber 50% de
              Desconto para adquirir a Cartilha de Números da Sorte</h3>

            <p style="text-align: center;">Toque no botão abaixo para finalizar a sua compra</p>

            <h5 style="text-align: center;"><s>De: R$ 97</s></h5>
            <h4 style="text-align: center; font-weight: bold">Por: R$ 47 ou 6x de R$ 8,64</h4>
            <a style="text-align: center;" href="https://pay.kirvano.com/3e0dfcde-b62d-426a-8e21-ffbcbf51e504" class="btn-submit btn-enviar">Quero aproveitar essa oportunidade</a>

            <p style="text-align: center; color: red; font-weight: bold">⚠️ Aviso: se você fechar, essa oferta
              desaparecerá para sempre</p>
            <img style="text-align: center; width: 100%" src="../assets/cartilha.png">
          </div>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "DownsellCartaKirvano",
    data() {
    return {
    }
  },
  };
</script>