<style>
.bg-mgi {
    background-image: url('../assets/bg/dark-bg-wide.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

  
  .btn-send {
    margin: 10px;
    padding: 10px 20px;
    background-color: #fff;
    color: #060606;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-send:hover {
    background-color: #cfcfcf;
  }

  .btn-submit {
    margin: 10px;
    padding: 10px 20px;
    background-color: #29cb14;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-submit:hover {
    background-color: #29cb14;
  }

</style>
<template>
  <div class="bg-mgi" style="height: 100vh">
    <div class="col-12">
      <div id="vid_664d453bc22e8f000b8c5287" style="position:relative;width:100%;padding: 177.77777777777777% 0 0;">
        <img id="thumb_664d453bc22e8f000b8c5287"
          src="https://images.converteai.net/0a246cdd-c6bd-48cb-b2bf-d2a8c03c2dd5/players/664d453bc22e8f000b8c5287/thumbnail.jpg"
          style="position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;display:block;">
        <div id="backdrop_664d453bc22e8f000b8c5287"
          style="position:absolute;top:0;width:100%;height:100%;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);">
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "UpsellVideo",
  data() {

  },
  mounted() {
    this.loadVideo();
  },
  methods: {
    loadVideo(){
      const script = document.createElement("script");
      script.src = "https://scripts.converteai.net/0a246cdd-c6bd-48cb-b2bf-d2a8c03c2dd5/players/664d453bc22e8f000b8c5287/player.js";
      script.async = true;
      document.head.appendChild(script);
    }
  }
};
</script>