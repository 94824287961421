import axios from 'axios';

const apiKey = `0e087ec55dcbe7b2d7992d6b69b519fb`;
const apiClient = axios.create({
    baseURL: ' https://astroapi-4.divineapi.com/western-api/v1',
    headers: {
      Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FzdHJvYXBpLTEuZGl2aW5lYXBpLmNvbS9hcGkvYXV0aC1hcGktdXNlciIsImlhdCI6MTcxNDY1MTI5OCwibmJmIjoxNzE0NjUxMjk4LCJqdGkiOiJLb2E3YTBiaFZnV3RDcVRJIiwic3ViIjoiMTgyMiIsInBydiI6ImU2ZTY0YmIwYjYxMjZkNzNjNmI5N2FmYzNiNDY0ZDk4NWY0NmM5ZDcifQ.Dfn1lPOqYtpRpZquAoDogzCz4Dsa45I7IugzzIC7M4U`
    }
  });

export default {
    postPlanetaryPositions(data) {
      const payload = {
        api_key: apiKey, // A chave da API
        full_name: 'nome aleatorio', // Nome completo do usuário
        day: data.day, // Dia de nascimento
        month: data.month, // Mês de nascimento
        year: data.year, // Ano de nascimento
        hour: data.hour, // Hora de nascimento
        min: data.min, // Minutos de nascimento
        sec: data.sec, // Segundos de nascimento
        gender: data.gender, // Gênero
        place: data.place, // Local de nascimento
        lat: data.lat, // Latitude
        lon: data.lon, // Longitude
        tzone: data.tzone, // Fuso horário
        lan:  'pt' // Idioma, padrão para inglês se não especificado
      };
  
      return apiClient.post('/planetary-positions', payload);
    },
    postHouseCusps(data) {
      const payload = {
        api_key: apiKey, // A chave da API
        full_name: 'nome aleatorio', // Nome completo do usuário
        day: data.day, // Dia de nascimento
        month: data.month, // Mês de nascimento
        year: data.year, // Ano de nascimento
        hour: data.hour, // Hora de nascimento
        min: data.min, // Minutos de nascimento
        sec: data.sec, // Segundos de nascimento
        gender: data.gender, // Gênero
        place: data.place, // Local de nascimento
        lat: data.lat, // Latitude
        lon: data.lon, // Longitude
        tzone: data.tzone, // Fuso horário
        lan:  'pt' // Idioma, padrão para inglês se não especificado
      };
  
      return apiClient.post('/house-cusps', payload);
    },
    postNatalWheelChart(data) {
      const payload = {
        api_key: apiKey, // A chave da API
        full_name: 'nome aleatorio', // Nome completo do usuário
        day: data.day, // Dia de nascimento
        month: data.month, // Mês de nascimento
        year: data.year, // Ano de nascimento
        hour: data.hour, // Hora de nascimento
        min: data.min, // Minutos de nascimento
        sec: data.sec, // Segundos de nascimento
        gender: data.gender, // Gênero
        place: data.place, // Local de nascimento
        lat: data.lat, // Latitude
        lon: data.lon, // Longitude
        tzone: data.tzone, // Fuso horário
        lan:  'pt',
        wheel_background: '#fffbe3',
        outter_background: '#dfabff',
        wheel_color: '#660b9e'
      };
  
      return apiClient.post('/natal-wheel-chart', payload);
    },
    postGeneralSignReport(data, planetName) {
      const payload = {
        api_key: apiKey, // A chave da API
        full_name: 'nome aleatorio', // Nome completo do usuário
        day: data.day, // Dia de nascimento
        month: data.month, // Mês de nascimento
        year: data.year, // Ano de nascimento
        hour: data.hour, // Hora de nascimento
        min: data.min, // Minutos de nascimento
        sec: data.sec, // Segundos de nascimento
        gender: data.gender, // Gênero
        place: data.place, // Local de nascimento
        lat: data.lat, // Latitude
        lon: data.lon, // Longitude
        tzone: data.tzone, // Fuso horário
        lan:  'pt' // Idioma, padrão para inglês se não especificado
      };
  
      return apiClient.post('/general-sign-report/' + planetName, payload);
    },
    postGeneralHouseReport(data, planetName) {
      const payload = {
        api_key: apiKey, // A chave da API
        full_name: 'nome aleatorio', // Nome completo do usuário
        day: data.day, // Dia de nascimento
        month: data.month, // Mês de nascimento
        year: data.year, // Ano de nascimento
        hour: data.hour, // Hora de nascimento
        min: data.min, // Minutos de nascimento
        sec: data.sec, // Segundos de nascimento
        gender: data.gender, // Gênero
        place: data.place, // Local de nascimento
        lat: data.lat, // Latitude
        lon: data.lon, // Longitude
        tzone: data.tzone, // Fuso horário
        lan:  'pt' // Idioma, padrão para inglês se não especificado
      };
  
      return apiClient.post('/general-house-report/' + planetName, payload);
    },
    postAscendantReport(data) {
      const payload = {
        api_key: apiKey, // A chave da API
        full_name: 'nome aleatorio', // Nome completo do usuário
        day: data.day, // Dia de nascimento
        month: data.month, // Mês de nascimento
        year: data.year, // Ano de nascimento
        hour: data.hour, // Hora de nascimento
        min: data.min, // Minutos de nascimento
        sec: data.sec, // Segundos de nascimento
        gender: data.gender, // Gênero
        place: data.place, // Local de nascimento
        lat: data.lat, // Latitude
        lon: data.lon, // Longitude
        tzone: data.tzone, // Fuso horário
        lan:  'pt' // Idioma, padrão para inglês se não especificado
      };
  
      return apiClient.post('/ascendant-report', payload);
    }
  };