<template>
    <div>
        <div class="container-fluid fundo-mahila">
          <div class="row">
            <div class="col-4"></div>
            <div class=" col-md-4 col-sm-12 popup-mahila">
                <div>
                    <h3 style="text-align: center; color: red; font-weight: bold">ATENÇÃO: Não saia dessa página<br> Falta pouco!</h3>
                    <br>
                    <p style="text-align: center;">Para receber seu Desenho da Alma Gêmea. Preencha o formulário abaixo.</p>
                    <form @submit.prevent="fetchTagsByEmail" v-if="this.enviouForm == false && this.loading == false">
                        <div class="form-group">
                          <label for="email">Insira o e-mail que deseja receber o Desenho</label>
                          <input type="email" id="email" class="form-control" v-model="email">
                          <br>
                          <label for="signo">Selecione sua preferência sexual:</label>
                          <select id="signo" class="form-control" v-model="signoSelecionado">
                            <option v-for="item in preferenciaSexual" :key="item.id" :value="item.id">
                              {{ item.nome }}
                            </option>
                          </select>
                          <br>
                          <button type="submit" style="width: 100%" class="btn btn-success">Enviar</button>
                        </div>
                    </form>
                    <div v-if="this.loading == true">
                      <h3 style="text-align: center; color: red; font-weight: bold">Carregando... Não saia da página!</h3>
                      <p style="text-align: center;">O envio dos seus dados está sendo feito para o meu formulário. Caso você saia antes de carregar, não receberei seus dados.</p>
                      <img style="text-align: center" src="../assets/icons/spiner.gif">
                    </div>
                    <div style="background-color: #d4f7d0; padding: 30px" v-if="this.enviouForm == true">
                        <p>Agora que você preencheu o formulário, em breve você receberá o seu Desenho da Alma Gêmea. (Lembre-se de verificar na aba de Spam e de Lixo)</p>
                    </div>
                </div>
              <hr>
              <h3 style="text-align: center; color: red; font-weight: bold">Precisa de suporte?</h3>
              <p>
                Não se preocupe, envie um e-mail com o seu Nome Completo, CPF e conte o que está acontecendo para podermos ajudar.
                <br><br>
                O e-mail do meu suporte é: desenhoalmagemea@mahilaluz.com (note que o nosso e-mail não tem o ".br" no final)
              </p>
            </div>
          </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    data() {
        return {
        email: '',
        signoSelecionado: '',
        preferenciaSexual: [
            { id: 1, nome: 'Gosto de Homens' },
            { id: 2, nome: 'Gosto de Mulheres' }
        ],
        imageAttachmentsHomem: [
          'https://mahilaluz.com/ag/h/dibhkjbdui.png',
          'https://mahilaluz.com/ag/h/dnwmqopq.png',
          'https://mahilaluz.com/ag/h/e2qjawxi.png',
          'https://mahilaluz.com/ag/h/mbvnqiwpcj.png',
          'https://mahilaluz.com/ag/h/xvjmkqwpo.png',
          'https://mahilaluz.com/ag/h/xwcdikjaw.png'
        ],
        imageAttachmentsMulher: [
          'https://mahilaluz.com/ag/m/djwjentas.png',
          'https://mahilaluz.com/ag/m/dmwqpfmn.png',
          'https://mahilaluz.com/ag/m/eekafwej.png',
          'https://mahilaluz.com/ag/m/mnwkjw.png',
          'https://mahilaluz.com/ag/m/wmendiawip.png'
        ],
        imageAttachmentsHomemC: [
          'https://mahilaluz.com/ag/ch/83b336fe8d81.png',
          'https://mahilaluz.com/ag/ch/a7b6d64ed9a0.png',
          'https://mahilaluz.com/ag/ch/a91adjajnwm123.png',
          'https://mahilaluz.com/ag/ch/d132jhjjads1.png',
          'https://mahilaluz.com/ag/ch/f1a7af882de7.png'
        ],
        imageAttachmentsMulherC: [
          'https://mahilaluz.com/ag/cm/95bb30afab78.png',
          'https://mahilaluz.com/ag/cm/36877d2ba83b.png',
          'https://mahilaluz.com/ag/cm/d506a1590093.png',
          'https://mahilaluz.com/ag/cm/d275167faebe.png',
          'https://mahilaluz.com/ag/cm/d12832198a21398.png'
        ],
        enviouForm: false,
        tags: null,
        imgEmail: null,
        loading: false
        };
    },
    methods: {
      getRandomImage() {

        const tagFound = this.tags.find(tag => tag.tag === '17');
        if(!tagFound){
          if(this.signoSelecionado == 1){
            const randomIndex = Math.floor(Math.random() * this.imageAttachmentsHomem.length);
            this.imgEmail = this.imageAttachmentsHomem[randomIndex];
          }else if(this.signoSelecionado == 2){
            const randomIndex = Math.floor(Math.random() * this.imageAttachmentsMulher.length);
            this.imgEmail = this.imageAttachmentsMulher[randomIndex];
          }
        }
        else{
          if(this.signoSelecionado == 1){
            const randomIndex = Math.floor(Math.random() * this.imageAttachmentsHomemC.length);
            this.imgEmail = this.imageAttachmentsHomemC[randomIndex];
          }else if(this.signoSelecionado == 2){
            const randomIndex = Math.floor(Math.random() * this.imageAttachmentsMulherC.length);
            this.imgEmail = this.imageAttachmentsMulherC[randomIndex];
          }
        }

      },
      async fetchTagsByEmail() {
        this.loading = true;
        const url = `https://verificatag-ag.mahilaluz.workers.dev?email=${encodeURIComponent(this.email)}`;

        try {
          const response = await axios.post(url);

          this.tags = response.data;
          this.enviarEmail();
        } catch (error) {
          console.error('Failed to fetch tags:', error);
        }
      },
      async fetchImageAsBase64(url) {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(',')[1]); // Obtém apenas a parte base64
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      },
      async enviarEmail(){

        this.getRandomImage();
        const imgBase64 = await this.fetchImageAsBase64(this.imgEmail);
        
        const brevoApiKey = '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';

        // Configurar o payload da API da Brevo
        const data = {
          sender: { email: 'desenhoalmagemea@mahilaluz.com', name: ' Mahila Luz' },
          to: [
            { email: this.email },
            { email: 'desenhoalmagemea@mahilaluz.com' } // Adicione seu próprio e-mail aqui
          ],
          subject: 'Chegou Seu Desenho da Alma Gêmea',
          htmlContent: '<p>Oii. Tudo bem? <br><br> Seu Desenho da Alma Gêmea está em anexo nesse email.<br>Qualquer dúvida pode me chamar aqui. <br><br> Muita luz para você💜💜</p>',
          attachment: [
            {
              content: imgBase64,
              name: 'desenhodaalmagemea.png'
            }
          ],
          //scheduledAt: sendAt
        };

        // Configurar os headers da requisição
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'api-key': brevoApiKey
          }
        };

        try {
          const response = await axios.post('https://api.brevo.com/v3/smtp/email', data, config);
          console.log('Email scheduled successfully:', response.data);
          this.enviouForm = true;
          this.loading = false;
        } catch (error) {
          console.error('Error scheduling email:', error);
        }
      }
    },
}
</script>