<template>
    <div>
        <div class="container-fluid fundo-mahila">
          <div class="row">
            <div class="col-4"></div>
            <div class=" col-md-4 col-sm-12 popup-mahila">
                <div>
                    <h3 style="text-align: center; color: red; font-weight: bold">ATENÇÃO: Não saia dessa página<br> Falta pouco!</h3>
                    <br>
                    <p style="text-align: center;">Para começar a receber o seu Correio das Estrelas. Preencha o formulário abaixo com o e-mail desejado e o seu signo.</p>
                    <form @submit.prevent="inscreverNoBrevo" v-if="this.enviouForm == false">
                        <div class="form-group">
                          <label for="email">Insira o e-mail que deseja receber o Correio</label>
                          <input type="email" id="email" class="form-control" v-model="email">
                          <br>
                          <label for="signo">Selecione o seu signo:</label>
                          <select id="signo" class="form-control" v-model="signoSelecionado">
                            <option v-for="item in signos" :key="item.id" :value="item.id">
                              {{ item.nome }}
                            </option>
                          </select>
                          <br>
                          <button type="submit" style="width: 100%" class="btn btn-success">Enviar</button>
                        </div>
                    </form>
                    <div style="background-color: #d4f7d0; padding: 30px" v-if="this.enviouForm == true">
                        <p>Agora que você preencheu o formulário, abra o e-mail que eu te enviei. (Lembre-se de verificar na aba de Spam e de Lixo)<br><br>
                        Lá irei te explicar sobre o funcionamento do Correio das Estrelas.
                        <br><br><b>Aqui vai um breve resumo:</b>
                        <br><br>
                        O seu Correio das Estrelas será enviado diretamente no seu e-mail todos os domingos.
                        <br><br>
                        Assim você terá todas as informações necessárias para começar sua semana da melhor forma possível.
                        <br><br>
                        Para mais detalhes, veja o e-mail.<br>
                        Beijo e muita luz.
                        </p>
                    </div>
                </div>
              <hr>
              <h3 style="text-align: center; color: red; font-weight: bold">Precisa de suporte?</h3>
              <p>
                Não se preocupe, envie um e-mail com o seu Nome Completo, CPF e conte o que está acontecendo para podermos ajudar.
                <br><br>
                O e-mail do meu suporte é: contato@mahilaluz.com (note que o nosso e-mail não tem o ".br" no final)
              </p>
            </div>
          </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

    export default {
        data() {
            return {
            signoSelecionado: '',
            signos: [
                { id: 16, nome: 'Peixes' },
                { id: 13, nome: 'Aquário' },
                { id: 15, nome: 'Capricórnio' },
                { id: 14, nome: 'Sagitário' },
                { id: 12, nome: 'Escorpião' },
                { id: 11, nome: 'Libra' },
                { id: 10, nome: 'Virgem' },
                { id: 9, nome: 'Leão' },
                { id: 8, nome: 'Câncer' },
                { id: 7, nome: 'Gêmeos' },
                { id: 6, nome: 'Touro' },
                { id: 5, nome: 'Áries' }
            ],
            enviouForm: false,
            };
        },
        methods: {
            inscreverNoBrevo() {

            axios.post(`https://correiodasestrelas.mahilaluz.workers.dev?email=${encodeURIComponent(this.email)}&tagId=${encodeURIComponent(this.signoSelecionado)}`)
            .then(response => {
                this.enviouForm = true;
                console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
            }
        },
    }
</script>