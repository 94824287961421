<style>
  @media print {
    body, element {
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
    }
  }

  pre {
    max-width: 100%; /* Limita a largura máxima do elemento <pre> */
    white-space: pre-wrap;
  }

  .avoid-page-break {
    page-break-inside: avoid; /* Evita quebras de página dentro do elemento */
  }
</style>
<template>
  <div>
    <div class="container-fluid fundo-mahila">
      <div class="row">
        <div class="col-4"></div>
        <div class="col-sm-12 popup-mahila">
            <div>
              <div v-if="this.loading == true">
                <h3 style="text-align: center; color: red; font-weight: bold">Carregando... Não saia da página!</h3>
                <p style="text-align: center;">O envio dos seus dados está sendo feito para o meu formulário. Caso você saia antes de carregar, não receberei seus dados.</p>
                <img style="text-align: center" src="../assets/icons/spiner.gif">
              </div>

              <div v-if="this.loading == false">
                <h3 style="text-align: center; color: green; font-weight: bold">Envio realizado com sucesso!</h3>
                <p style="text-align: center;">Já tenho suas informações. Em breve seu mapa astrológico chegará em seu email</p>
              </div>
              
            </div>
          <hr>
          <h3 style="text-align: center; color: red; font-weight: bold">Precisa de suporte?</h3>
          <p>
            Não se preocupe, envie um e-mail com o seu Nome Completo, CPF e conte o que está acontecendo para podermos ajudar.
            <br><br>
            O e-mail do meu suporte é: contato@mahilaluz.com (note que o nosso e-mail não tem o ".br" no final)
          </p>
        </div>
      </div>
    </div>
    
    <div class="row" v-if="this.podeCarregar" id="mapaAstrologico">
      <div id="svg-container">
        <img :src="this.graficoSigno.data.base64_image" style="display: none" ref="svgImage">
      </div>
      <!--
        <div class="col-12" style="position: relative; height: 100vh; width: 100vw; page-break-after: always; margin: 0; padding: 0; display: flex; flex-direction: column; justify-content: center; align-items: center; overflow: hidden;">
          <img src="@/assets/capa_mapa.png" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 10;">
          <h2 style="z-index: 90; color: white; text-align: center; font-family: 'Open Sans'; font-size: 48px; align-self: center; margin-top: auto;">{{this.full_name}}</h2>
        </div> 
        
        <div class="col-12" style="height: 100vh;">
        </div>
        

        
        <div class="col-12" style="width: 794px;">
          <div class="avoid-page-break" style="background-color: #87337F; font-family: 'Sora'; height: 100px; display: flex; justify-content: center; align-items: center;"><h3 style="text-align: center; color: white; font-size: 20px; margin: 0; text-transform: uppercase;">SUA ESSÊNCIA: SOL EM {{this.reportSun.data.sign_name}}</h3></div>
          <p class="avoid-page-break" style="padding-left: 35px; padding-right: 35px; padding-top: 10px">
            <pre>{{this.reportSun.data.report}}</pre>
          </p>
          <div class="avoid-page-break" style="background-color: #87337F; font-family: 'Sora'; height: 100px; display: flex; justify-content: center; align-items: center;"><h3 style="text-align: center; color: white; font-size: 20px; margin: 0; text-transform: uppercase;">SUAS EMOÇÕES: LUA EM {{this.reportMoon.data.sign_name}}</h3></div>
          <p class="avoid-page-break" style="padding-left: 35px; padding-right: 35px; padding-top: 10px">
            <pre>{{this.reportMoon.data.report}}</pre>
          </p>
        </div>
        

        
        <div class="col-12" style="width: 794px;">
          <h2 class="avoid-page-break" style="left:50%; color: #0C0C0C; text-align: center; font-family: 'Open Sans';font-size: 24px">AS CASAS NOS SIGNOS</h2>
          <div class="avoid-page-break" style="background-color: #87337F; font-family: 'Sora'; height: 100px; display: flex; justify-content: center; align-items: center;"><h3 style="text-align: center; color: white; font-size: 20px; margin: 0; text-transform: uppercase;">SUA PERSONALIDADE: ASCENDENTE EM {{this.reportAscendent.data.sign}}</h3></div>
          <p class="avoid-page-break" style="padding-left: 35px; padding-right: 35px; padding-top: 10px">
            <pre>{{this.reportAscendent.data.result}}</pre>
          </p>
          <div v-for="report in sortReports()" :key="report.planet_name">
            <div class="avoid-page-break" style="background-color: #87337F; font-family: 'Sora'; height: 100px; display: flex; justify-content: center; align-items: center;"><h3 style="text-align: center; color: white; font-size: 20px; margin: 0; text-transform: uppercase;">{{ report.data.planet_name }} NA CASA {{ report.data.house }}</h3></div>
            <p class="avoid-page-break" style="padding-left: 35px; padding-right: 35px; padding-top: 10px">
              <pre>{{ report.data.report }}</pre>
            </p>
          </div>
        </div>
        

        
        <div class="col-12" style="width: 794px;">
          <h2 class="avoid-page-break" style="left:50%; color: #0C0C0C; text-align: center; font-family: 'Open Sans';font-size: 24px">PLANETAS NOS SIGNOS </h2>
          <div class="avoid-page-break" style="background-color: #87337F; font-family: 'Sora'; height: 100px; display: flex; justify-content: center; align-items: center;"><h3 style="text-align: center; color: white; font-size: 20px; margin: 0; text-transform: uppercase;">SEU FORMA DE COMUNICAR E RACIOCINAR: MERCÚRIO EM {{this.reportMercury.data.sign_name}}</h3></div>
          <p class="avoid-page-break" style="padding-left: 35px; padding-right: 35px; padding-top: 10px">
            <pre>{{this.reportMercury.data.report}}</pre>
          </p>
          <div class="avoid-page-break" style="background-color: #87337F; font-family: 'Sora'; height: 100px; display: flex; justify-content: center; align-items: center;"><h3 style="text-align: center; color: white; font-size: 20px; margin: 0; text-transform: uppercase;">SUA FORMA DE SE RELACIONAR: VÊNUS EM {{this.reportVenus.data.sign_name}}</h3></div>
          <p class="avoid-page-break" style="padding-left: 35px; padding-right: 35px; padding-top: 10px">
            <pre>{{this.reportVenus.data.report}}</pre>
          </p>
          <div class="avoid-page-break" style="background-color: #87337F; font-family: 'Sora'; height: 100px; display: flex; justify-content: center; align-items: center;"><h3 style="text-align: center; color: white; font-size: 20px; margin: 0; text-transform: uppercase;">SEU IMPULSO E SUA DETERMINAÇÃO: MARTE EM {{this.reportMars.data.sign_name}}</h3></div>
          <p class="avoid-page-break" style="padding-left: 35px; padding-right: 35px; padding-top: 10px">
            <pre>{{this.reportMars.data.report}}</pre>
          </p>
          <div class="avoid-page-break" style="background-color: #87337F; font-family: 'Sora'; height: 100px; display: flex; justify-content: center; align-items: center;"><h3 style="text-align: center; color: white; font-size: 20px; margin: 0; text-transform: uppercase;">SUA EXPANSÃO E SEU DESENVOLVIMENTO PESSOAL: JÚPITER EM {{this.reportJupiter.data.sign_name}}</h3></div>
          <p class="avoid-page-break" style="padding-left: 35px; padding-right: 35px; padding-top: 10px">
            <pre>{{this.reportJupiter.data.report}}</pre>
          </p>
          <div class="avoid-page-break" style="background-color: #87337F; font-family: 'Sora'; height: 100px; display: flex; justify-content: center; align-items: center;"><h3 style="text-align: center; color: white; font-size: 20px; margin: 0; text-transform: uppercase;">SEUS DESAFIOS: SATURNO EM {{this.reportSaturn.data.sign_name}}</h3></div>
          <p class="avoid-page-break" style="padding-left: 35px; padding-right: 35px; padding-top: 10px">
            <pre>{{this.reportSaturn.data.report}}</pre>
          </p>
        </div>
        

        
        <div class="col-12" style="width: 794px;">
          <div class="avoid-page-break" style="background-color: #87337F; font-family: 'Sora'; height: 100px; display: flex; justify-content: center; align-items: center;"><h3 style="text-align: center; color: white; font-size: 20px; margin: 0">COMENTÁRIOS DE ENCERRAMENTO</h3></div>
          <p class="avoid-page-break" style="padding-left: 35px; padding-right: 35px; padding-top: 10px">
            As análises astrológicas apresentadas neste relatório baseiam-se exclusivamente na precisão das informações fornecidas sobre sua data, horário e local de nascimento, considerando inclusive o horário de verão, caso se aplique.
            <br>
            Destaco que uma variação, mesmo que mínima, de apenas uma hora no horário de nascimento pode comprometer significativamente a precisão deste relatório.
            <br>
            Sem um horário de nascimento exato, a análise oferecida por este Mapa Astrológico fica limitada, impedindo a determinação do signo ascendente, das posições das casas planetárias e dos aspectos entre o Sol, a Lua, os Planetas e o Ascendente.
            <br>
            Este relatório é um estudo detalhado e profundo sobre sua personalidade e trajetória astrológica, e recomendo que seja lido com calma, dedicação e em um ambiente que favoreça a reflexão.
            <br>
            Obviamente, você é uma pessoa muito mais complexa do que algumas páginas escritas podem descrever. O que foi escrito aqui por mim, Mahila Luz, são tendências as quais sua natureza humana apresenta mais inclinações para determinadas atitudes, temperamentos e ações.
            <br><br>
            Tenha uma excelente leitura e reflexão!
            <br><br>
            Um beijo e muita luz
            <br><br>
            Mahila Luz
            <br><br>
            Atendimento ao Cliente EXCLUSIVO pelo email<br>
            contato@mahilaluz.com
          </p>
        </div>
      -->
      
    </div>
  </div>
    <!--<button @click="exportToPDF" class="w-full p-2 mt-4 bg-green-500 text-white rounded-md hover:bg-green-600">Exportar PDF</button>-->
</template>

<script>
import html2pdf from "html2pdf.js";
import apiService from '../services/apiService.js';
import jsPDF from 'jspdf';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';

export default {
  name: "SignoDetalhes",
  props: {
    full_name: {
      type: String,
      required: true
    },
    day: {
      type: Number,
      required: true
    },
    month: {
      type: Number,
      required: true
    },
    year: {
      type: Number,
      required: true
    },
    hour: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      required: true
    },
    sec: {
      type: Number,
      required: true
    },
    gender: {
      type: String,
      required: true
    },
    place: {
      type: String,
      required: true
    },
    lat: {
      type: Number,
      required: true
    },
    lon: {
      type: Number,
      required: true
    },
    tzone: {
      type: Number,
      required: true
    },
    email: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      reportSun: null,
      podeCarregar: false,
      reports: [],
      icons: {
        location: require('@/assets/icons/location.png'),
        calendar: require('@/assets/icons/calendar.png'),
        clock: require('@/assets/icons/clock.png')
      },
      pngDataUrl: null,
      loading: true,
    }
  },
  created(){
    this.chamarApi()
    .then(() => {
      this.scheduleEmail().then(() => {
        this.loading = false;
      })
    })
    .catch(error => {
      console.error('Erro ao chamar a API ou agendar e-mail:', error);
    });
  },
  methods: {
    habilitarCarregamento() {
      this.podeCarregar = true;
    },
    exportToPDF() {
      const pdfContent = this.$el;
      const options = {
        margin: 1,
        filename: `Signo.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
      };

      html2pdf().from(pdfContent).set(options).save();
    },
    chamarApi() {
      return new Promise((resolve, reject) => {
        const userData = {
          full_name: this.full_name,
          day: this.day,
          month: this.month,
          year: this.year,
          hour: this.hour,
          min: this.min,
          sec: this.sec,
          gender: this.gender,
          place: this.place,
          lat: this.lat,
          lon: this.lon,
          tzone: this.tzone
        };

        if(userData.day == 32){
          userData.day = 31;
        }
    
        const promises = [
          apiService.postPlanetaryPositions(userData).then(response => {
            this.posicoesAstros = response.data;
          }).catch(error => {
            console.error('Error fetching planetary positions:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postHouseCusps(userData).then(response => {
            this.posicoesCasas = response.data;
          }).catch(error => {
            console.error('Error fetching HouseCusps:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postNatalWheelChart(userData).then(response => {
            this.graficoSigno = response.data;
          }).catch(error => {
            console.error('Error fetching NatalWheelChart:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralSignReport(userData, 'sun').then(response => {
            this.reportSun = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralSignReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralSignReport(userData, 'moon').then(response => {
            this.reportMoon = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralSignReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralSignReport(userData, 'mars').then(response => {
            this.reportMars = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralSignReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralSignReport(userData, 'mercury').then(response => {
            this.reportMercury = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralSignReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralSignReport(userData, 'venus').then(response => {
            this.reportVenus = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralSignReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralSignReport(userData, 'jupiter').then(response => {
            this.reportJupiter = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralSignReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralSignReport(userData, 'saturn').then(response => {
            this.reportSaturn = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralSignReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralHouseReport(userData, 'sun').then(response => {
            this.reports.reportHouseSun = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralHouseReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralHouseReport(userData, 'moon').then(response => {
            this.reports.reportHouseMoon = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralHouseReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralHouseReport(userData, 'mars').then(response => {
            this.reports.reportHouseMars = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralHouseReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralHouseReport(userData, 'mercury').then(response => {
            this.reports.reportHouseMercury = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralHouseReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralHouseReport(userData, 'venus').then(response => {
            this.reports.reportHouseVenus = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralHouseReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralHouseReport(userData, 'jupiter').then(response => {
            this.reports.reportHouseJupiter = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralHouseReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralHouseReport(userData, 'saturn').then(response => {
            this.reports.reportHouseSaturn = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralHouseReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralHouseReport(userData, 'uranus').then(response => {
            this.reports.reportHouseUranus = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralHouseReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralHouseReport(userData, 'neptune').then(response => {
            this.reports.reportHouseNeptune = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralHouseReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postGeneralHouseReport(userData, 'pluto').then(response => {
            this.reports.reportHousePluto = response.data;
          }).catch(error => {
            console.error('Error fetching GeneralHouseReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          }),

          apiService.postAscendantReport(userData).then(response => {
            this.reportAscendent = response.data;
          }).catch(error => {
            console.error('Error fetching AscendantReport:', error);
            this.error = "Erro ao obter dados: " + error.message;
          })
        ];

        // Usar Promise.all para aguardar todas as promessas
        Promise.all(promises)
        .then(() => {
          this.convertToPng();
          this.generatePDFWithImage();
          resolve();
        })
        .catch(error => {
          console.error('Error with one or more API calls:', error);
          reject(error);
        });
      });
    },
    sortReports() {
      // Transforma o objeto 'reports' em um array de objetos e filtra fora os nulos
      let reportsArray = Object.values(this.reports).filter(report => report !== null && report.data && report.data.house != null);

      // Ordena o array com base na propriedade 'house' de cada objeto dentro de 'data'
      reportsArray.sort((a, b) => a.data.house - b.data.house);

      // O array ordenado está agora disponível para uso posterior
      return reportsArray;
    },
    async compressPDF(pdfDoc) {
      const pdfBytes = pdfDoc.output('arraybuffer');
      const pdfLibDoc = await PDFDocument.load(pdfBytes);
      
      // Remover metadados não essenciais
      pdfLibDoc.setTitle('');
      pdfLibDoc.setAuthor('');
      pdfLibDoc.setSubject('');
      pdfLibDoc.setKeywords([]);
      pdfLibDoc.setProducer('');
      pdfLibDoc.setCreator('');

      // Configurar a compressão adicional
      const compressedPdfBytes = await pdfLibDoc.save({
        useObjectStreams: false,
        compress: true,
      });

      // Baixar o PDF comprimido
      const blob = new Blob([compressedPdfBytes], { type: 'application/pdf' });
      console.log(blob);
      return compressedPdfBytes;
    },
    generatePDF() {
      // Certifique-se de que o DOM está atualizado antes de capturar o conteúdo
      this.$nextTick(() => {
        const element = document.getElementById('mapaAstrologico');
        html2pdf().set({
          margin: [0, 0, 0, 0], // Sem margens
          filename: 'documento.pdf',
          pagebreak: { mode: ['css', 'legacy'] },
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: {
            dpi: 300, // Para melhor qualidade de impressão
            scale: 2, // Ajuste conforme necessário para garantir nitidez
            letterRendering: true,
          },
          jsPDF: {
            unit: 'pt',
            format: 'a4',
            orientation: 'portrait'
          }
        }).from(element).save();
      });
    },
    async generatePDFWithImage() {
      const doc = new jsPDF({ compression: 'FAST' });
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      // URL ou caminho local para a imagem
      const imageUrl = require('@/assets/capa_mapa.png');

      // Converter imagem para formato aceito pelo jsPDF
      const imageData = await this.loadImage(imageUrl);

      // Função auxiliar para adicionar texto com verificação de espaço
      /*const addTextWithPageCheck = (text, fontSize, fontType, textColor, x, startY) => {
        doc.setFontSize(fontSize);
        doc.setFont('helvetica', fontType);
        doc.setTextColor(...textColor);
        let textLines = doc.splitTextToSize(text, pageWidth - x * 2);
        let y = startY;
        textLines.forEach(line => {
          if (y + fontSize > pageHeight - 10) { // Checa se a próxima linha cabe na página
            doc.addPage();
            y = 10; // Margem superior após nova página
          }
          doc.text(line, x, y);
          y += fontSize * 0.8; // Ajusta o espaçamento da linha baseado no tamanho da fonte
        });
      };*/

      
      const addTextWithPageCheck = (text, fontSize, fontType, textColor, x, startY, lineHeightFactor = 0.8) => {
        doc.setFontSize(fontSize);
        doc.setFont('helvetica', fontType);
        doc.setTextColor(...textColor);
        let textLines = doc.splitTextToSize(text, pageWidth - x * 2);
        let y = startY;
        textLines.forEach(line => {
          if (y + fontSize > pageHeight - 10) { // Checa se a próxima linha cabe na página
            doc.addPage();
            y = 10; // Margem superior após nova página
          }
          doc.text(line, x, y);
          y += fontSize * lineHeightFactor; // Espaçamento de linha ajustado
        });
        return y; // Retorna a posição Y após adicionar o texto
      };

      // Adicionar imagem ao documento
      // A imagem cobrirá a totalidade da página A4
      doc.addImage(imageData, 'JPEG', 0, 0, 210, 297, undefined, 'FAST');

      // Configurações de texto
      doc.setTextColor(255, 255, 255); // Define a cor do texto para branco
      doc.setFontSize(24); // Tamanho do texto
      doc.setFont('helvetica', 'bold'); // Fonte e estilo

      // Adicionar texto
      const text = this.full_name;
      doc.text(text, pageWidth / 2, 287, { align: 'center' }); // Posicionado centralizado e na parte inferior da página

      // Adicionar nova página
      doc.addPage();

        // Configurações de texto para a segunda página
        doc.setTextColor(255, 255, 255); // Branco
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');

        // Adicionar texto centralizado na parte superior da segunda página
        const secondPageText = this.full_name;
        doc.text(secondPageText, pageWidth / 2, 10, { align: 'center' }); // Posicionado centralizado e no topo da página

        // Configurações e dados para ícones e textos
        const items = [
          { icon: this.icons.location, text: this.place },
          { icon: this.icons.calendar, text: this.day + '/' + this.month + '/' + this.year },
          { icon: this.icons.clock, text: this.hour + ':' + this.min }
        ];

        let yPos = 20; // Posição vertical para os ícones e textos
        const iconWidth = 7; // Largura do ícone em mm
        const iconHeight = 7; // Altura do ícone em mm
        const textSpacing = 2; // Espaço entre o ícone e o texto
        const gapBetweenSets = 5; // Espaço entre os conjuntos

        // Calcular a largura total necessária para todos os ícones e textos
        let totalWidth = 0;
        items.forEach(item => {
          const textWidth = doc.getTextWidth(item.text);
          totalWidth += iconWidth + textSpacing + textWidth + gapBetweenSets;
        });
        totalWidth -= gapBetweenSets; // Remove o último espaçamento extra

        // Calcular posição inicial para centralizar
        let xPos = (pageWidth - totalWidth) / 1.8;

        // Definir posição e tamanho do retângulo roxo que preenche toda a largura da página
        const rectX2 = 0; // Início do retângulo na extremidade esquerda da página
        const rectY2 = 0; // Um pouco acima do texto centralizado
        const rectWidth = pageWidth; // Largura total da página
        const rectHeight2 = 32; // Altura suficiente para cobrir o texto e os ícones

        // Desenhar retângulo roxo
        doc.setFillColor(128, 0, 128); // Cor roxa
        doc.rect(rectX2, rectY2, rectWidth, rectHeight2, 'F'); // 'F' para preenchimento

        // Definir configurações de texto e adicionar texto centralizado novamente, agora por cima do retângulo
        doc.setTextColor(255, 255, 255); // Branco
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.text(secondPageText, pageWidth / 2, 15, { align: 'center' }); // Posicionado centralizado e no topo da página

        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');

        // Loop para adicionar cada ícone e texto
        for (const item of items) {
          const iconData = await this.loadImage(item.icon);
          doc.addImage(iconData, 'PNG', xPos, yPos, iconWidth, iconHeight, undefined, 'FAST'); // Adicionar ícone
          const textWidth = doc.getTextWidth(item.text);
          doc.text(item.text, xPos + iconWidth + textSpacing, yPos + 5); // Adicionar texto ao lado do ícone

          // Calcular posição X para o próximo item
          xPos += iconWidth + textSpacing + textWidth + gapBetweenSets;
        }

        // Gráfico Astral
        // Carregar e adicionar SVG
        // Posições calculadas para centralizar a imagem
        const imgWidth = 100;
        const imgHeight = 100;
        const xImg = (pageWidth - imgWidth) / 2;
        const yImg = yPos + iconHeight + 10; // Ajuste para posicionar a imagem abaixo dos ícones
        doc.addImage(this.pngDataUrl, 'PNG', xImg, yImg, imgWidth, imgHeight, undefined, 'FAST');

        // Ajustar yPos para as tabelas abaixo da imagem
        yPos = yImg + imgHeight + 10; // Ajuste para dar espaço após a imagem

        // Exemplo de dados da tabela
        const headers = ['Planeta', 'Signo', 'Casa'];
        const data = [
          [this.posicoesAstros.data[1].name, this.posicoesAstros.data[1].sign, this.posicoesAstros.data[1].house],
          [this.posicoesAstros.data[2].name, this.posicoesAstros.data[2].sign, this.posicoesAstros.data[2].house],
          [this.posicoesAstros.data[4].name, this.posicoesAstros.data[4].sign, this.posicoesAstros.data[4].house],
          [this.posicoesAstros.data[5].name, this.posicoesAstros.data[5].sign, this.posicoesAstros.data[5].house],
          [this.posicoesAstros.data[3].name, this.posicoesAstros.data[3].sign, this.posicoesAstros.data[3].house],
          [this.posicoesAstros.data[6].name, this.posicoesAstros.data[6].sign, this.posicoesAstros.data[6].house],
          [this.posicoesAstros.data[7].name, this.posicoesAstros.data[7].sign, this.posicoesAstros.data[7].house],
          [this.posicoesAstros.data[10].name, this.posicoesAstros.data[10].sign, this.posicoesAstros.data[10].house],
          [this.posicoesAstros.data[11].name, this.posicoesAstros.data[11].sign, this.posicoesAstros.data[11].house],
          [this.posicoesAstros.data[12].name, this.posicoesAstros.data[12].sign, this.posicoesAstros.data[12].house],
          [this.posicoesAstros.data[0].name, this.posicoesAstros.data[0].sign, this.posicoesAstros.data[0].house],
          ['Meio do Céu', this.posicoesAstros.data[13].sign, this.posicoesAstros.data[13].house],
          ['Descendente', this.posicoesAstros.data[14].sign, this.posicoesAstros.data[14].house],
          ['Fundo do Céu', this.posicoesAstros.data[15].sign, this.posicoesAstros.data[15].house]
        ];

        // URLs das imagens base64 para cada linha da coluna "Planeta"
        const images = [
          require('@/assets/icons/sol.png'), // Imagem 1
          require('@/assets/icons/lua.png'), // Imagem 2
          require('@/assets/icons/mercurio.png'), // Imagem 3
          require('@/assets/icons/venus.png'), // Imagem 4
          require('@/assets/icons/marte.png'), // Imagem 5
          require('@/assets/icons/jupiter.png'), // Imagem 6
          require('@/assets/icons/saturno.png'), // Imagem 7
          require('@/assets/icons/netuno.png'), // Imagem 8
          require('@/assets/icons/urano.png'), // Imagem 9
          require('@/assets/icons/plutao.png')
        ];

        // URLs das imagens base64 para cada linha da coluna "Planeta"
        const signImages  = {
          'Áries': require('@/assets/icons/aries.png'),
          'Touro': require('@/assets/icons/touro.png'),
          'Gêmeos': require('@/assets/icons/gemeos.png'),
          'Câncer': require('@/assets/icons/cancer.png'),
          'Leão': require('@/assets/icons/leao.png'),
          'Virgem': require('@/assets/icons/virgem.png'),
          'Libra':  require('@/assets/icons/libra.png'),
          'Escorpião': require('@/assets/icons/escorpiao.png'),
          'Sagitário': require('@/assets/icons/sagitario.png'),
          'Capricórnio': require('@/assets/icons/capricornio.png'),
          'Aquário': require('@/assets/icons/aquario.png'),
          'Peixes': require('@/assets/icons/peixes.png')
        };


        const newHeaders = ['Casa', 'Signo'];
        const newData = [
          [this.posicoesCasas.data.houses[0].house, this.posicoesCasas.data.houses[0].sign],
          [this.posicoesCasas.data.houses[1].house, this.posicoesCasas.data.houses[1].sign],
          [this.posicoesCasas.data.houses[2].house, this.posicoesCasas.data.houses[2].sign],
          [this.posicoesCasas.data.houses[3].house, this.posicoesCasas.data.houses[3].sign],
          [this.posicoesCasas.data.houses[4].house, this.posicoesCasas.data.houses[4].sign],
          [this.posicoesCasas.data.houses[5].house, this.posicoesCasas.data.houses[5].sign],
          [this.posicoesCasas.data.houses[6].house, this.posicoesCasas.data.houses[6].sign],
          [this.posicoesCasas.data.houses[7].house, this.posicoesCasas.data.houses[7].sign],
          [this.posicoesCasas.data.houses[8].house, this.posicoesCasas.data.houses[8].sign],
          [this.posicoesCasas.data.houses[9].house, this.posicoesCasas.data.houses[9].sign],
          [this.posicoesCasas.data.houses[10].house, this.posicoesCasas.data.houses[10].sign],
          [this.posicoesCasas.data.houses[11].house, this.posicoesCasas.data.houses[11].sign]
        ];

        // Definindo posições iniciais para a tabela original
        let startX = 10;
        let startY = yPos; // Começar abaixo da imagem
        const cellWidth = 40;
        const cellHeight = 10;
        const imageWidth = 5; // Largura da imagem em px
        const imageHeight = 5; // Altura da imagem em px

        // Desenhando cabeçalhos da tabela original
        doc.setTextColor(0, 0, 0); // Preto
        doc.setFont("helvetica", "bold");

        headers.forEach((header, index) => {
          doc.text(header, startX + index * cellWidth, startY);
        });
        doc.setFont("helvetica", "normal");

        // Desenhando dados da tabela original
        data.forEach((row, rowIndex) => {
          row.forEach((cell, cellIndex) => {
            const yPos = startY + (rowIndex + 1) * cellHeight;
            
            if (cellIndex === 0 && rowIndex < data.length - 4) {
              const imageYPos = yPos - (cellHeight / 2);
              // Adicionar imagem ao lado esquerdo da célula "Planeta" (exceto as últimas 4 linhas)
              doc.addImage(images[rowIndex], 'PNG', startX - imageWidth - 2, imageYPos, imageWidth, imageHeight, undefined, 'FAST');
            }

            if (cellIndex === 1 && signImages[cell]) {
              // Adicionar imagem ao lado esquerdo da célula "Signo"
              const imageYPos = yPos - (cellHeight / 2);
              doc.addImage(signImages[cell], 'PNG', startX + cellWidth - imageWidth - 2, imageYPos, imageWidth, imageHeight, undefined, 'FAST');
            }

            doc.text(String(cell), startX + cellIndex * cellWidth, yPos);
          });
        });

        // Ajustando posição inicial para a nova tabela
        let newStartX = startX + headers.length * cellWidth + 5; // Adiciona um espaço entre as tabelas
        let newStartY = startY;

        // Desenhando cabeçalhos da nova tabela
        doc.setFont("helvetica", "bold");
        newHeaders.forEach((header, index) => {
          doc.text(header, newStartX + index * cellWidth, newStartY);
        });
        doc.setFont("helvetica", "normal");
        // Desenhando dados da nova tabela
        newData.forEach((row, rowIndex) => {
          row.forEach((cell, cellIndex) => {
            const yPos = newStartY + (rowIndex + 1) * cellHeight;
            if (cellIndex === 1 && signImages[cell]) {
              // Adicionar imagem ao lado esquerdo da célula "Signo"
              const imageYPos = yPos - (cellHeight / 2);
              doc.addImage(signImages[cell], 'PNG', newStartX + cellWidth - imageWidth - 2, imageYPos, imageWidth, imageHeight, undefined, 'FAST');
            }
            
            doc.text(String(cell), newStartX + cellIndex * cellWidth, newStartY + (rowIndex + 1) * cellHeight);
          });
        });


      doc.addPage();

      const rectHeight = 20; // Altura do retângulo para o fundo, em mm

      // Coordenadas e dimensões do retângulo de fundo
      let rectY = 0; // Posição vertical inicial do retângulo no topo da página
      doc.setFillColor(128, 0, 128); // Cor roxa
      doc.rect(0, rectY, pageWidth, rectHeight, 'F'); // Desenha o retângulo roxo desde o topo

      // Adicionar texto centralizado
      doc.setTextColor(255, 255, 255); // Cor do texto branco
      doc.setFontSize(14); // Tamanho da fonte
      doc.setFont('helvetica', 'bold'); // Fonte em negrito
      // Centraliza o texto verticalmente dentro do retângulo
      const textY = rectY + (rectHeight / 2) + 3; // Centraliza o texto no meio do retângulo, ajustando para a altura da fonte
      doc.text(('SUA ESSÊNCIA: SOL EM ' + this.reportSun.data.sign_name).toUpperCase(), pageWidth / 2, textY, { align: 'center' }); // Centraliza o texto horizontalmente e ajusta verticalmente

      // Adicionar texto normal abaixo do fundo roxo
      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      
      const lineHeightFactor = 0.8; // Ajuste esse valor conforme necessário para mais ou menos espaço entre linhas
      const normalTextY = rectY + rectHeight + 10; // Posição Y um pouco abaixo do fundo roxo
      const endY = addTextWithPageCheck(this.reportSun.data.report, 13, 'normal', [0, 0, 0], 10, normalTextY, lineHeightFactor);
      //addTextWithPageCheck(this.reportSun.data.report, 13, 'normal', [0, 0, 0], 10, normalTextY); // Adiciona texto com gerenciamento de página

      const nextContentY = endY + 10;

      // Verificar a distância até o final da página
      if (nextContentY + 10 > pageHeight) {
          doc.addPage();
          rectY = 10; // Reinicia no topo da nova página
      } else {
          rectY += rectHeight + 10; // Incrementar rectY para continuar adicionando mais conteúdo
      }

      // Adicionar o segundo título com fundo roxo
      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text(('SUAS EMOÇÕES: LUA EM ' + this.reportMoon.data.sign_name).toUpperCase(), pageWidth / 2, nextContentY + (20 / 2) + 3, { align: 'center' });

      // Texto normal abaixo do segundo título
      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      const normalTextY2 = nextContentY + 20 + 10;
      const endY2 = addTextWithPageCheck(this.reportMoon.data.report, 13, 'normal', [0, 0, 0], 10, normalTextY2, lineHeightFactor);
      //addTextWithPageCheck(this.reportMoon.data.report, 13, 'normal', [0, 0, 0], 10, normalTextY2); // Adiciona texto com gerenciamento de página

      const nextContentY2 = endY2 + 10;

      // Verificar a distância até o final da página
      if (nextContentY2 + 10 > pageHeight) {
          doc.addPage();
          rectY = 10; // Reinicia no topo da nova página
      } else {
          rectY += rectHeight + 10; // Incrementar rectY para continuar adicionando mais conteúdo
      }

      // Adicionar o terceiro título com fundo roxo
      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY2, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text(('SUA PERSONALIDADE: ASCENDENTE EM ' + this.reportAscendent.data.sign).toUpperCase(), pageWidth / 2, nextContentY2 + (20 / 2) + 3, { align: 'center' });

      // Texto normal abaixo do terceiro título
      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      const normalTextY3 = nextContentY2 + 20 + 10;
      const endY3 = addTextWithPageCheck(this.reportAscendent.data.result, 13, 'normal', [0, 0, 0], 10, normalTextY3, lineHeightFactor);
      
      let nextContentY3 = endY3 + 10;

      //CASAS ASTROLÓGICAS
      const sortedReports = this.sortReports(); // Obtem os reports ordenados

      sortedReports.forEach(report => {

      // Verifica se há espaço suficiente para o retângulo do cabeçalho
      if (nextContentY3 + rectHeight + 20 > pageHeight) {
        doc.addPage();
        nextContentY3 = 0; // Reinicia no topo da nova página
      }

        // Adiciona um título com fundo roxo para cada report
        doc.setFillColor(128, 0, 128); // Cor roxa
        doc.rect(0, nextContentY3, pageWidth, 20, 'F'); // Desenha o retângulo roxo
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(255, 255, 255);
        // Texto do título (modifique conforme sua estrutura de dados)
        doc.text((report.data.planet_name + ' NA CASA ' + report.data.house).toUpperCase(), pageWidth / 2, nextContentY3 + 10 + 3, { align: 'center' });

        // Texto normal abaixo do título
        nextContentY3 += 30; // Move para baixo após o título
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(13);
        doc.setFont('helvetica', 'normal');
        nextContentY3 = addTextWithPageCheck(report.data.report, 13, 'normal', [0, 0, 0], 10, nextContentY3, lineHeightFactor);

        nextContentY3 += 10; // Espaço extra antes do próximo título
      });

      // Verificar a distância até o final da página
      if (nextContentY3 + 10 > pageHeight) {
          doc.addPage();
          rectY = 10; // Reinicia no topo da nova página
      } else {
          rectY += rectHeight + 10; // Incrementar rectY para continuar adicionando mais conteúdo
      }

      // Adicionar MERCÚRIO
      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY3, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text(('SUA FORMA DE COMUNICAR E RACIOCINAR: MERCÚRIO EM ' + this.reportMercury.data.sign_name).toUpperCase(), pageWidth / 2, nextContentY3 + (20 / 2) + 3, { align: 'center' });

      // Texto normal abaixo do terceiro título
      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      const normalTextY4 = nextContentY3 + 20 + 10;
      const endY4 = addTextWithPageCheck(this.reportMercury.data.report, 13, 'normal', [0, 0, 0], 10, normalTextY4, lineHeightFactor);
      
      let nextContentY4 = endY4 + 10;

      // Verificar a distância até o final da página
      if (nextContentY4 + 10 > pageHeight) {
          doc.addPage();
          rectY = 10; // Reinicia no topo da nova página
      } else {
          rectY += rectHeight + 10; // Incrementar rectY para continuar adicionando mais conteúdo
      }

      // Adicionar VÊNUS
      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY4, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text(('SUA FORMA DE SE RELACIONAR: VÊNUS EM ' + this.reportVenus.data.sign_name).toUpperCase(), pageWidth / 2, nextContentY4 + (20 / 2) + 3, { align: 'center' });

      // Texto normal abaixo do terceiro título
      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      const normalTextY5 = nextContentY4 + 20 + 10;
      const endY5 = addTextWithPageCheck(this.reportVenus.data.report, 13, 'normal', [0, 0, 0], 10, normalTextY5, lineHeightFactor);
      
      let nextContentY5 = endY5 + 10;

      // Verificar a distância até o final da página
      if (nextContentY5 + 10 > pageHeight) {
        doc.addPage();
        rectY = 10; // Reinicia no topo da nova página
      } else {
        rectY += rectHeight + 10; // Incrementar rectY para continuar adicionando mais conteúdo
      }

      // Adicionar MARTE
      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY5, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text(('SEU IMPULSO E SUA DETERMINAÇÃO: MARTE EM ' + this.reportMars.data.sign_name).toUpperCase(), pageWidth / 2, nextContentY5 + (20 / 2) + 3, { align: 'center' });

      // Texto normal abaixo do terceiro título
      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      const normalTextY6 = nextContentY5 + 20 + 10;
      const endY6 = addTextWithPageCheck(this.reportMars.data.report, 13, 'normal', [0, 0, 0], 10, normalTextY6, lineHeightFactor);
      
      let nextContentY6 = endY6 + 10;

      // Verificar a distância até o final da página
      if (nextContentY6 + 10 > pageHeight) {
        doc.addPage();
        rectY = 10; // Reinicia no topo da nova página
      } else {
        rectY += rectHeight + 10; // Incrementar rectY para continuar adicionando mais conteúdo
      }

      // Adicionar JÚPITER
      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY6, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold'); 
      doc.setTextColor(255, 255, 255);
      doc.text(('SUA EXPANSÃO E SEU DESENVOLVIMENTO PESSOAL: JÚPITER EM ' + this.reportJupiter.data.sign_name).toUpperCase(), pageWidth / 2, nextContentY6 + (20 / 2) + 3, { align: 'center' });

      // Texto normal abaixo do terceiro título
      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal 
      const normalTextY7 = nextContentY6 + 20 + 10;
      const endY7 = addTextWithPageCheck(this.reportJupiter.data.report, 13, 'normal', [0, 0, 0], 10, normalTextY7, lineHeightFactor);
      
      let nextContentY7 = endY7 + 10;

      
      // Verificar a distância até o final da página
      if (nextContentY7 + 10 > pageHeight) {
        doc.addPage();
        rectY = 10; // Reinicia no topo da nova página
      } else {
        rectY += rectHeight + 10; // Incrementar rectY para continuar adicionando mais conteúdo
      }

      // Adicionar SATURNO 
      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY7, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text(('SEUS DESAFIOS: SATURNO EM ' + this.reportSaturn.data.sign_name).toUpperCase(), pageWidth / 2, nextContentY7 + (20 / 2) + 3, { align: 'center' });

      // Texto normal abaixo do terceiro título
      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      const normalTextY8 = nextContentY7 + 20 + 10;
      const endY8 = addTextWithPageCheck(this.reportSaturn.data.report, 13, 'normal', [0, 0, 0], 10, normalTextY8, lineHeightFactor);
      
      let nextContentY8 = endY8 + 10;
      
      
      // Verificar a distância até o final da página
      if (nextContentY8 + 10 > pageHeight) {
        doc.addPage();
        rectY = 10; // Reinicia no topo da nova página
      } else {
        rectY += rectHeight + 10; // Incrementar rectY para continuar adicionando mais conteúdo
      }

      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY8, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text(('SOL EM HARMONIA COM LUA').toUpperCase(), pageWidth / 2, nextContentY8 + (20 / 2) + 3, { align: 'center' });

      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      const normalTextY9 = nextContentY8 + 20 + 10;
      const endY9 = addTextWithPageCheck('Quando o Sol forma uma harmonia com a Lua, reflete um período de equilíbrio e entendimento entre nossas necessidades emocionais e nossa essência vital. Esta configuração celeste favorece a integração da personalidade, onde sentimentos e ações encontram um terreno comum. É um excelente momento para fortalecer relações pessoais, pois promove uma maior sintonia com os outros, facilitando a comunicação e a expressão de afetos. Aproveite esta fase para resolver desafios emocionais, pois a energia está propícia para entendimentos e reconciliações.', 13, 'normal', [0, 0, 0], 10, normalTextY9, lineHeightFactor);
      
      let nextContentY9 = endY9 + 10;

      // Verificar a distância até o final da página
      if (nextContentY9 + 10 > pageHeight) {
        doc.addPage();
        rectY = 10; // Reinicia no topo da nova página
      } else {
        rectY += rectHeight + 10; // Incrementar rectY para continuar adicionando mais conteúdo
      }

      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY9, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text(('SOL EM FUSÃO COM VÊNUS').toUpperCase(), pageWidth / 2, nextContentY9 + (20 / 2) + 3, { align: 'center' });

      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      const normalTextY10 = nextContentY9 + 20 + 10;
      const endY10 = addTextWithPageCheck('Quando o Sol e Vênus se encontram em fusão no céu, abre-se um período de harmonização intensa das nossas emoções e dos nossos desejos. Esta configuração celeste favorece o florescimento das relações, atraindo amor e beleza para nossa vida cotidiana. É um momento excelente para expressar afeto, buscar prazeres e apreciar as artes. Nossa presença se torna mais magnética, e há uma grande capacidade de atrair o que desejamos. Aproveite essa fase para fortalecer laços, celebrar a vida e permitir que sua verdadeira essência brilhe intensamente.', 13, 'normal', [0, 0, 0], 10, normalTextY10, lineHeightFactor);
      
      let nextContentY10 = endY10 + 10;
    
      // Verificar a distância até o final da página
      if (nextContentY10 + 10 > pageHeight) {
          doc.addPage();
          rectY = 10; // Reinicia no topo da nova página
      } else {
          rectY += rectHeight + 10; // Incrementar rectY para continuar adicionando mais conteúdo
      }

      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY10, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text(('SOL EM HARMONIA COM JÚPITER').toUpperCase(), pageWidth / 2, nextContentY10 + (20 / 2) + 3, { align: 'center' });

      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      const normalTextY11 = nextContentY10 + 20 + 10;
      const endY11 = addTextWithPageCheck("Quando o Sol forma uma harmonia com Júpiter, é um momento de expansão e otimismo. Essa configuração astral sugere um aumento na sorte e nas oportunidades, além de um sentido ampliado de possibilidades. Este é um período propício para crescer, aprender e explorar novos horizontes. Pode-se sentir mais confiante e entusiasmado com a vida, o que facilita a conquista de metas e objetivos. Aproveite essa fase para investir em seu desenvolvimento pessoal e profissional, pois o universo está alinhado para apoiar seu crescimento e sucesso.", 13, 'normal', [0, 0, 0], 10, normalTextY11, lineHeightFactor);

      let nextContentY11 = endY11 + 10;
      // Verificar a distância até o final da página
      if (nextContentY11 + 10 > pageHeight) {
          doc.addPage();
          nextContentY11 = 10; // Reinicia no topo da nova página
      } else {
          nextContentY11 += rectHeight + 10; // Incrementar rectY para continuar adicionando mais conteúdo
      }

      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY11, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text(('SOL FUSÃO COM URANO').toUpperCase(), pageWidth / 2, nextContentY11 + (20 / 2) + 3, { align: 'center' });

      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      const normalTextY12 = nextContentY11 + 20 + 10;
      const endY12 = addTextWithPageCheck("Quando o Sol forma uma fusão com Urano, é um momento de despertar e renovação. Esta combinação estimula a expressão individual e a busca por liberdade, provocando mudanças súbitas e surpreendentes. É uma fase propícia para inovações e rupturas com o passado, onde novas ideias e tecnologias podem desempenhar um papel crucial. Este aspecto incentiva a quebrar as convenções e a explorar territórios desconhecidos, favorecendo a criatividade e a originalidade. Aproveite essa energia para se reinventar e abraçar as transformações que se apresentam.", 13, 'normal', [0, 0, 0], 10, normalTextY12, lineHeightFactor);

      let nextContentY12 = endY12 + 10;

      // Verificar a distância até o final da página
      if (nextContentY12 + 10 > pageHeight) {
          doc.addPage();
          rectY = 10; // Reinicia no topo da nova página
      } else {
          rectY += rectHeight + 10; // Incrementar rectY para continuar adicionando mais conteúdo
      }

      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY12, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text(('LUA EM FUSÃO COM MARTE').toUpperCase(), pageWidth / 2, nextContentY12 + (20 / 2) + 3, { align: 'center' });

      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      const normalTextY13 = nextContentY12 + 20 + 10;
      const endY13 = addTextWithPageCheck("Quando a Lua se funde com Marte, surge uma energia emocional intensa e dinâmica. Esse aspecto astrológico incentiva ações impulsivas e uma expressão apaixonada de sentimentos. A combinação pode levar a uma necessidade de confronto e de resolver questões pendentes, oferecendo uma excelente oportunidade para limpar o ar e começar de novo. Este é um momento para abraçar a coragem emocional, enfrentar desafios diretamente e aprender a gerenciar melhor a própria impulsividade. Use esse período para agir com confiança, mas tome cuidado para não deixar que suas emoções dominem sua razão.", 13, 'normal', [0, 0, 0], 10, normalTextY13, lineHeightFactor);

      let nextContentY13 = endY13 + 10;

      // Verificar a distância até o final da página
      if (nextContentY13 + 10 > pageHeight) {
          doc.addPage();
          rectY = 10; // Reinicia no topo da nova página
      } else {
        rectY += rectHeight + 10; // Incrementar nextContentY para continuar adicionando mais conteúdo
      }

      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY13, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text(('LUA EM OPOSIÇÃO COM JÚPITER').toUpperCase(), pageWidth / 2, nextContentY13 + (20 / 2) + 3, { align: 'center' });

      doc.setTextColor(0, 0, 0); // Cor do texto preto
      doc.setFontSize(13); // Tamanho da fonte menor para o texto normal
      doc.setFont('helvetica', 'normal'); // Fonte normal
      const normalTextY14 = nextContentY13 + 20 + 10;
      const endY14 = addTextWithPageCheck("Quando a Lua forma uma oposição com Júpiter, somos convidados a equilibrar nossas necessidades emocionais com nossa expansão e crescimento pessoal. Este aspecto desafia a harmonizar o conforto do familiar e o chamado para explorar novos horizontes. Pode surgir um conflito entre o desejo de segurança emocional e a busca por aventuras e conhecimentos. Aprender a integrar essas duas forças pode trazer uma profunda compreensão de como nossas emoções influenciam nossa visão de mundo e vice-versa, promovendo um crescimento pessoal enriquecedor e equilibrado.", 13, 'normal', [0, 0, 0], 10, normalTextY14, lineHeightFactor);

      let nextContentY14 = endY14 + 10;

      // Verificar espaço para nova seção
      if (nextContentY14 + 10 > pageHeight) {
          doc.addPage();
          rectY = 10;
      } else {
        rectY += rectHeight + 10;
      }

      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY14, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text('LUA EM HARMONIA COM URANO', pageWidth / 2, nextContentY14 + (20 / 2) + 3, { align: 'center' });

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(13);
      doc.setFont('helvetica', 'normal');
      const normalTextY15 = nextContentY14 + 20 + 10;
      const endY15 = addTextWithPageCheck("A harmonia entre a Lua e Urano impulsiona uma energia eletrizante e inovadora. Este aspecto desafia a estabilidade emocional da Lua com a imprevisibilidade de Urano, mas também oferece oportunidades para abraçar mudanças positivas e liberar a criatividade. A mente intuitiva da Lua se sincroniza com a natureza revolucionária de Urano, promovendo insights e inspirações únicas. É um momento para se libertar das limitações emocionais e abraçar novas perspectivas com entusiasmo.", 13, 'normal', [0, 0, 0], 10, normalTextY15, lineHeightFactor);

      let nextContentY15 = endY15 + 10;

      // Verificar espaço para nova seção
      if (nextContentY15 + 10 > pageHeight) {
          doc.addPage();
          rectY = 10;
      } else {
        rectY += rectHeight + 10;
      }
      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY15, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text('MERCÚRIO EM FUSÃO COM VÊNUS', pageWidth / 2, nextContentY15 + (20 / 2) + 3, { align: 'center' });

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(13);
      doc.setFont('helvetica', 'normal');
      const normalTextY16 = nextContentY15 + 20 + 10;
      const endY16 = addTextWithPageCheck("Mercúrio e Vênus em fusão trazem uma sinergia encantadora, fundindo comunicação e amor. Esta combinação promove diálogos suaves e afetuosos, tornando as interações sociais mais agradáveis e expressivas. A mente analítica de Mercúrio se une à beleza e à sensibilidade de Vênus, criando uma atmosfera de harmonia e compreensão nas relações interpessoais. É um momento propício para expressar sentimentos com clareza e charme, facilitando a conexão emocional e intelectual com os outros.", 13, 'normal', [0, 0, 0], 10, normalTextY16, lineHeightFactor);

      let nextContentY16 = endY16 + 10;

      // Verificar espaço para nova seção
      if (nextContentY16 + 30 > pageHeight) { // Verificação ajustada para garantir espaço para o retângulo e o texto
          doc.addPage();
          nextContentY16 = 10; // Reiniciar a posição vertical no topo da nova página
      }

      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY16, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text('MERCÚRIO EM FUSÃO COM URANO', pageWidth / 2, nextContentY16 + (20 / 2) + 3, { align: 'center' });

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(13);
      doc.setFont('helvetica', 'normal');
      const normalTextY17 = nextContentY16 + 20 + 10;
      const endY17 = addTextWithPageCheck("Mercúrio em fusão com Urano indica uma mente meticulosa e disciplinada. Essa fusão combina a comunicação clara e estruturada de Mercúrio com a seriedade e o foco de Urano. As ideias são expressas de forma precisa e pragmática, refletindo um pensamento lógico e uma abordagem cuidadosa em todas as comunicações.", 13, 'normal', [0, 0, 0], 10, normalTextY17, lineHeightFactor);

      let nextContentY17 = endY17 + 10;

      // Verificar espaço para nova seção
      if (nextContentY17 + 30 > pageHeight) { // Verificação ajustada para garantir espaço para o retângulo e o texto
          doc.addPage();
          nextContentY17 = 10; // Reiniciar a posição vertical no topo da nova página
      }


      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY17, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text('VÊNUS EM FUSÃO COM SATURNO', pageWidth / 2, nextContentY17 + (20 / 2) + 3, { align: 'center' });

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(13);
      doc.setFont('helvetica', 'normal');
      const normalTextY18 = nextContentY17 + 20 + 10;
      const endY18 = addTextWithPageCheck("Vênus e Saturno se unem em fusão, marcando um encontro entre o amor e a responsabilidade. Esta conjunção desafia a encontrar equilíbrio entre o prazer e o compromisso, exigindo uma abordagem madura nos relacionamentos e nas finanças. É um momento para construir relacionamentos sólidos, baseados na confiança e no respeito mútuo, mas também para enfrentar realidades financeiras com prudência e disciplina. Essa fusão pode trazer estabilidade e durabilidade aos assuntos relacionados ao amor, beleza e recursos financeiros.", 13, 'normal', [0, 0, 0], 10, normalTextY18, lineHeightFactor);

      let nextContentY18 = endY18 + 10;

      // Verificar espaço para nova seção
      if (nextContentY18 + 10 > pageHeight) {
          doc.addPage();
          rectY = 10;
      } else {
        rectY += rectHeight + 10;
      }

      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY18, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text('JÚPITER EM HARMONIA COM URANO', pageWidth / 2, nextContentY18 + (20 / 2) + 3, { align: 'center' });

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(13);
      doc.setFont('helvetica', 'normal');
      const normalTextY19 = nextContentY18 + 20 + 10;
      const endY19 = addTextWithPageCheck("A energia expansiva de Júpiter harmoniza-se com a imprevisibilidade de Urano, formando um aspecto de harmonia cósmica. Este alinhamento impulsiona a busca por novas possibilidades e aventuras excitantes, enquanto simultaneamente oferece a sabedoria necessária para aproveitar essas oportunidades de forma sábia e inovadora. É um momento para abraçar a mudança com otimismo e confiança, confiando na intuição para explorar territórios desconhecidos com entusiasmo e determinação.", 13, 'normal', [0, 0, 0], 10, normalTextY19, lineHeightFactor);

      let nextContentY19 = endY19 + 10;

      // Verificar espaço para nova seção
      if (nextContentY19 + 10 > pageHeight) {
          doc.addPage();
          rectY = 10;
      } else {
        rectY += rectHeight + 10;
      }

      doc.setFillColor(128, 0, 128);
      doc.rect(0, nextContentY19, pageWidth, 20, 'F');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text('COMENTÁRIOS DE ENCERRAMENTO', pageWidth / 2, nextContentY19 + (20 / 2) + 3, { align: 'center' });

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(13);
      doc.setFont('helvetica', 'normal');
      const normalTextY20 = nextContentY19 + 20 + 10;
      const endY20 = addTextWithPageCheck("As análises astrológicas apresentadas neste relatório baseiam-se exclusivamente na precisão das informações fornecidas sobre sua data, horário e local de nascimento, considerando inclusive o horário de verão, caso se aplique.\n\nDestaco que uma variação, mesmo que mínima, de apenas uma hora no horário de nascimento pode comprometer significativamente a precisão deste relatório.\n\nSem um horário de nascimento exato, a análise oferecida por este Mapa Astrológico fica limitada, impedindo a determinação do signo ascendente, das posições das casas planetárias e dos aspectos entre o Sol, a Lua, os Planetas e o Ascendente.\n\nEste relatório é um estudo detalhado e profundo sobre sua personalidade e trajetória astrológica, e recomendo que seja lido com calma, dedicação e em um ambiente que favoreça a reflexão.\n\nObviamente, você é uma pessoa muito mais complexa do que algumas páginas escritas podem descrever. O que foi escrito aqui por mim, Mahila Luz, são tendências as quais sua natureza humana apresenta mais inclinações para determinadas atitudes, temperamentos e ações.\n\nTenha uma excelente leitura e reflexão!\n\nUm beijo e muita luz\n\nMahila Luz\n\nAtendimento ao Cliente EXCLUSIVO pelo Whatsapp e email\ncontato@mahilaluz.com", 13, 'normal', [0, 0, 0], 10, normalTextY20, lineHeightFactor);

      let nextContentY20 = endY20 + 10;


      console.log(nextContentY20);

      // Salvar o PDF
      return doc;
    },
    async convertPDFToBase64(pdfBytes) {
      return new Promise((resolve) => {
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result.split(',')[1];
          resolve(base64data);
        };
      });
    },
    async scheduleEmail() {
      const brevoApiKey = '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';

      const date = new Date();
      date.setHours(date.getHours() + 1); // Adiciona 1 hora à data atual
      console.log(date); // Deve retornar a data e hora local ajustada corretamente

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      const sendAt = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000-03:00`;
      console.log(sendAt); // Deve retornar a data e hora local no formato desejado
      
      const pdfDoc = await this.generatePDFWithImage();
      const compressedPdfBytes = await this.compressPDF(pdfDoc);
      // Gerar o PDF e converter para base64
      const pdfBase64 = await this.convertPDFToBase64(compressedPdfBytes);

      const base64Size = (pdfBase64.length * (3 / 4)) / (1024 * 1024); // tamanho em MB
      if (base64Size > 20) {
        return;
      }

      // Configurar o payload da API da Brevo
      const data = {
        sender: { email: 'contato@mahilaluz.com', name: ' Mahila Luz' },
        to: [
          { email: this.email },
          { email: 'contato@mahilaluz.com' } // Adicione seu próprio e-mail aqui
        ],
        subject: 'Chegou Sua Leitura Personalizada - Mapa Astrológico',
        htmlContent: '<p>Oii. Tudo bem? <br><br> Finalizei a sua análise personalizada, vou te enviar em anexo. <br>Também aproveite para ler os bônus especiais que separei para você no link abaixo.<br><a href="https://drive.google.com/drive/folders/1-1j0JlfdaQO_jRdpfiWW4wt1soG2D0PD?usp=sharing">Clique aqui para acessar os bônus</a><br><br>Caso você tenha adquirido a cartilha de números da sorte, aguarde mais um pouco para receber sua cartilha. A análise da cartilha é feita separada da análise do mapa, irei começar a trabalhar na sua agora.<br><br>Qualquer dúvida pode me chamar aqui. <br><br> Muita luz para você💜💜</p>',
        attachment: [
          {
            content: pdfBase64,
            name: 'Leitura Personalizada - ' + this.full_name + '.pdf'
          }
        ],
        //scheduledAt: sendAt
      };

      // Configurar os headers da requisição
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'api-key': brevoApiKey
        }
      };

      try {
        const response = await axios.post('https://api.brevo.com/v3/smtp/email', data, config);
        console.log('Email scheduled successfully:', response.data);
      } catch (error) {
        console.error('Error scheduling email:', error);
      }
    },
    async loadImage(url) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => {
          let canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, img.width, img.height);
          resolve(canvas.toDataURL("image/png", 0.7)); // Captura os dados da imagem como JPEG
        };
        img.onerror = reject;
        img.crossOrigin = "Anonymous";
        img.src = url;
      });
    },
    convertToPng() {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        this.pngDataUrl = canvas.toDataURL('image/png', 0.7);
      };
      img.src = this.graficoSigno.data.base64_image;
    },

  }
};
</script>
